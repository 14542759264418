import {
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  number,
  distinct,
  between,
  isNull,
  notNull,
  notEquals,
} from '~/prix'
import { getDateRangeFilter } from './utils'
import { format } from 'date-fns'
import { parseDateToUTC } from '~/prix/types/date'

export default function legalEntityAttendanceAllCoursesEadQuery({
  groupColumn,
  idColumn,
  id,
  filter: filterIncoming,
}: any) {
  const filter = filterIncoming ? getDateRangeFilter(filterIncoming) : null

  const currentDate = parseDateToUTC(new Date())
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return query('legalEntity')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count: count(distinct(entity('legalEntity').property('id'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('streetId'),
      target: entity('street').property('id'),
      join: 'left',
    })
    [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
      current: entity('street').property('id'),
      target: entity('neighborhoodStreetIntersection').property('streetId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'left',
    })
    .where(
      ...[
        idColumn !== null && id !== null && idColumn === 'neighborhoodId'
          ? equals(entity('neighborhoodStreetIntersection').property(idColumn), string().value(id))
          : null,

        idColumn !== null &&
        id !== null &&
        idColumn !== 'countryIsoCode' &&
        idColumn !== 'neighborhoodId'
          ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
          : null,

        idColumn === 'countryIsoCode'
          ? equals(entity('country').property('iso'), number().value(Number(id!)))
          : null,

        between(
          entity('courseRegisterIndividual').property('registerDate'),
          !filter
            ? string().value(firstDayOfCurrentYearFormatted)
            : string().value(filter.startDate),
          !filter ? entity('dataSource').property('endDate') : string().value(filter.endDate),
        ),

        notNull(entity('legalEntityGeoprocessing').property('cityId')),

        notEquals(entity('legalEntity').property('state'), string().value('EX')),

        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(5),
        ),
      ].filter(truthy),
    )
    .limit(10000)
}
