import { format, subDays } from 'date-fns'
import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  boolean,
  descending,
  convert,
  month,
  date,
  between,
  ternary,
} from '~/prix'
import { parseDateToUTC } from '~/prix/types/date'

export default function monthAttendanceQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
  isPortfolioEad,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId: number
  isPortfolioEad?: boolean
}) {
  const currentDate = parseDateToUTC(new Date())
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')
  const currentDateFormatted = format(currentDate, 'yyyy-MM-dd')
  const aDayBeforeCurrentDate = format(subDays(currentDate, 1), 'yyyy-MM-dd')

  return query('legalEntity')
    .select({
      monthAndYear:
        dataSourceId === 5 || dataSourceId === 1
          ? convert(entity('courseRegisterIndividual').property('registerDate'), month())
          : convert(entity('legalEntityAttendance').property('startDate'), month()),
      total:
        dataSourceId === 5 || dataSourceId === 1
          ? count(distinct(entity('courseRegisterIndividual').property('id')))
          : count(distinct(entity('legalEntityAttendance').property('id'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'left',
    })
    [dataSourceId === 5 || dataSourceId === 1 ? 'join' : 'dummy']({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    [dataSourceId === 5 || dataSourceId === 1 ? 'join' : 'dummy']({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'left',
    })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('legalEntityAttendance').property('stateId'), string().value(stateId))
          : null,

        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(dataSourceId === 1 ? 5 : dataSourceId),
        ),

        isPortfolioEad === true
          ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
          : null,

        dataSourceId === 5 || dataSourceId === 1
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              date().value(`${firstDayOfCurrentYearFormatted}`),
              ternary(
                equals(
                  entity('dataSource').property('endDate'),
                  date().value(`${currentDateFormatted}`),
                ),
                date().value(`${aDayBeforeCurrentDate}`),
                entity('dataSource').property('endDate'),
              ),
            )
          : between(
              convert(entity('legalEntityAttendance').property('startDate'), date()),
              date().value(`${firstDayOfCurrentYearFormatted}`),
              ternary(
                equals(
                  entity('dataSource').property('endDate'),
                  date().value(`${currentDateFormatted}`),
                ),
                date().value(`${aDayBeforeCurrentDate}`),
                entity('dataSource').property('endDate'),
              ),
            ),
      ].filter(truthy),
    )
    .order(descending('monthAndYear'))
}
