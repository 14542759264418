import {
  boolean,
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  number,
  likeAny,
  isNull,
  distinct,
  ternary,
  between,
  every,
  isFalse,
  isTrue,
  convert,
  date,
} from '~/prix'
import { FilterHighlight, optionsBooleanValue } from '../legalEntityGeoprocessingMapLevels.data'
import { extractDates } from '../definedQueries/utils'
import { format } from 'date-fns'
import { parseDateToUTC } from '~/prix/types/date'

interface LegalEntitiesGeoprocessingQueryOptions {
  groupColumn: string
  idColumn: string | null
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
}

export default function legalEntitiesGeoprocessingQuery({
  groupColumn,
  idColumn,
  id,
  filter,
  highlight,
}: LegalEntitiesGeoprocessingQueryOptions) {
  const [filterStartDate, filterFinalDate] =
    filter?.value && typeof filter.value === 'string' ? extractDates(filter.value) : [null, null]

  const [highlightStartDate, highlightFinalDate] =
    highlight?.value && typeof highlight.value === 'string'
      ? extractDates(highlight.value)
      : [null, null]

  const currentDate = parseDateToUTC(new Date())
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return (
    query('legalEntity')
      .select({
        geoId: entity('legalEntityGeoprocessing').property(groupColumn),
        count:
          filter?.value || highlight?.value
            ? count(distinct(entity('legalEntity').property('id')))
            : count(entity('legalEntity').property('id')),
        highlight:
          highlight?.type === 'size' && highlight?.value !== 'micro' && highlight?.value !== 'isMei'
            ? count(
                distinct(
                  ternary(
                    equals(
                      entity('legalEntity').property(highlight?.type),
                      string().value(`${highlight.value!}`),
                    ),
                    entity('legalEntityGeoprocessing').property('legalEntityId'),
                    number().value(0),
                  ),
                ),
              )
            : highlight?.type === 'size' && highlight?.value === 'micro'
            ? count(
                distinct(
                  ternary(
                    every(
                      equals(entity('legalEntity').property('size'), string().value('micro')),
                      isFalse(entity('legalEntity').property('isMei')),
                    ),
                    entity('legalEntity').property('id'),
                    number().value(null as unknown as number),
                  ),
                ),
              )
            : highlight?.type === 'size' && highlight?.value === 'isMei'
            ? count(
                distinct(
                  ternary(
                    isTrue(entity('legalEntity').property('isMei')),
                    entity('legalEntity').property('id'),
                    number().value(null as unknown as number),
                  ),
                ),
              )
            : highlight?.type === 'attendance' &&
              highlight?.value &&
              highlightStartDate &&
              highlight.eadOption !== 'portfolio' &&
              highlight.eadOption !== 'individual'
            ? count(
                distinct(
                  ternary(
                    every(
                      equals(
                        entity('legalEntityAttendanceDataSource').property('dataSourceId'),
                        number().value(Number(highlight?.attendanceSource)),
                      ),
                      between(
                        String(highlight?.attendanceSource) === '5'
                          ? entity('courseRegisterIndividual').property('registerDate')
                          : convert(entity('legalEntityAttendance').property('startDate'), date()),

                        string().value(highlightStartDate),
                        string().value(String(highlightFinalDate)),
                      ),
                    ),
                    entity('legalEntity').property('id'),
                    number().value(null as any),
                  ),
                ),
              )
            : highlight?.type === 'attendance' &&
              highlight?.value &&
              highlight.eadOption === 'portfolio' &&
              highlightStartDate &&
              highlightFinalDate
            ? count(
                distinct(
                  ternary(
                    every(
                      equals(
                        entity('courseProduct').property(`isActivePortfolio`),
                        boolean().value(true),
                      ),
                      between(
                        entity('courseRegisterIndividual').property('registerDate'),
                        string().value(highlightStartDate),
                        string().value(highlightFinalDate),
                      ),
                    ),
                    entity('legalEntity').property('id'),
                    number().value(null as any),
                  ),
                ),
              )
            : highlight &&
              highlight.type === 'attendance' &&
              highlight.eadOption === 'individual' &&
              highlight.courseProduct &&
              highlightStartDate &&
              highlightFinalDate
            ? count(
                distinct(
                  ternary(
                    every(
                      equals(
                        entity('courseProduct').property('productCode'),
                        string().value(highlight.courseProduct),
                      ),
                      equals(
                        entity('courseProduct').property(`isActivePortfolio`),
                        boolean().value(true),
                      ),
                      between(
                        entity('courseRegisterIndividual').property('registerDate'),
                        string().value(highlightStartDate),
                        string().value(highlightFinalDate),
                      ),
                    ),
                    entity('legalEntity').property('id'),
                    number().value(null as any),
                  ),
                ),
              )
            : highlight?.type === 'cnaes' && highlight?.value
            ? count(
                likeAny(
                  entity('legalEntity').property(highlight?.type),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                ),
              )
            : highlight?.type === 'legalNatureId' && highlight?.value
            ? count(
                equals(
                  entity('legalEntity').property(highlight?.type),
                  string().value(`${highlight.value!}`),
                ),
              )
            : highlight?.type === 'segment' && highlight?.value
            ? count(
                likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                ),
              )
            : optionsBooleanValue.includes(`${highlight?.type}`) && highlight?.value
            ? count(equals(entity('legalEntity').property(highlight?.type), boolean().value(true)))
            : number().value(null as unknown as number),
      })
      .join({
        current: entity('legalEntity').property('id'),
        target: entity('legalEntityGeoprocessing').property('legalEntityId'),
        join: 'left',
      })
      [
        // EaD source
        (filter?.type === 'attendance' && filter?.attendanceSource === '5') ||
        (highlight?.type === 'attendance' && highlight?.attendanceSource === '5')
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityGeoprocessing').property('legalEntityId'),
        target: entity(`courseRegisterIndividual`).property('legalEntityId'),
        join: 'left',
      })
      [
        (filter?.type === 'attendance' && filter?.eadOption) ||
        (highlight?.type === 'attendance' && highlight?.eadOption)
          ? 'join'
          : 'dummy'
      ]({
        current: entity('courseRegisterIndividual').property('courseProductId'),
        target: entity(`courseProduct`).property('id'),
        join: 'left',
      })
      [
        (filter?.type === 'attendance' && filter?.attendanceSource) ||
        (highlight?.type === 'attendance' && highlight?.attendanceSource)
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntity').property('id'),
        target: entity('legalEntityAttendance').property('legalEntityId'),
        join: 'left',
      })
      [
        (filter?.type === 'attendance' && filter?.attendanceSource) ||
        (highlight?.type === 'attendance' && highlight?.attendanceSource)
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityAttendance').property('id'),
        target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
        join: 'left',
      })

      [
        (filter?.type === 'attendance' && filter?.attendanceSource) ||
        (highlight?.type === 'attendance' && highlight?.attendanceSource)
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
        target: entity('dataSource').property('id'),
        join: 'left',
      })

      // GEO
      .join({
        current: entity('legalEntityGeoprocessing').property('streetId'),
        target: entity('street').property('id'),
        join: 'left',
      })
      [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
        current: entity('street').property('id'),
        target: entity('neighborhoodStreetIntersection').property('streetId'),
        join: 'inner',
      })
      .join({
        current: entity('legalEntityGeoprocessing').property('countryId'),
        target: entity('country').property('id'),
        join: 'left',
      })
      .join({
        current: entity('legalEntityGeoprocessing').property('stateId'),
        target: entity('state').property('id'),
        join: 'left',
      })
      .join({
        current: entity('legalEntityGeoprocessing').property('cityId'),
        target: entity('city').property('id'),
        join: 'left',
      })
      .where(
        ...[
          isNull(entity('legalEntity').property('deletedAt')),
          equals(entity('legalEntity').property('registrationStatus'), string().value('active')),

          idColumn !== null && id !== null && idColumn === 'neighborhoodId'
            ? equals(
                entity('neighborhoodStreetIntersection').property(idColumn),
                string().value(id),
              )
            : null,

          idColumn !== null &&
          id !== null &&
          idColumn !== 'countryIsoCode' &&
          idColumn !== 'neighborhoodId'
            ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
            : null,

          idColumn === 'countryIsoCode'
            ? equals(entity('country').property('iso'), number().value(Number(id!)))
            : null,

          filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
            ? equals(
                entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                number().value(Number(filter?.attendanceSource)),
              )
            : null,

          filter &&
          filter.type === 'attendance' &&
          filter.eadOption === 'individual' &&
          filter.courseProduct &&
          filter.value
            ? every(
                equals(
                  entity('courseProduct').property('productCode'),
                  string().value(filter?.courseProduct),
                ),
                equals(
                  entity('courseProduct').property('isActivePortfolio'),
                  boolean().value(true),
                ),
              )
            : null,

          filter?.type === 'attendance' &&
          filter?.value &&
          filter.eadOption === 'portfolio' &&
          !filter?.courseProduct
            ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
            : null,

          filter?.type === 'size' &&
          filter?.value !== null &&
          filter?.value !== 'micro' &&
          filter?.value !== 'isMei'
            ? equals(
                entity('legalEntity').property(`${filter?.type}`),
                string().value(`${filter?.value!}`),
              )
            : null,

          filter?.type === 'size' && filter?.value === 'micro'
            ? every(
                equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter.value!}`),
                ),
                isFalse(entity('legalEntity').property('isMei')),
              )
            : null,

          filter?.type === 'size' && filter?.value === 'isMei'
            ? isTrue(entity('legalEntity').property('isMei'))
            : null,
          filter?.type === 'cnaes' && filter?.value
            ? likeAny(
                entity('legalEntity').property(filter?.type),
                filter?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              )
            : null,

          filter?.type === 'segment' && filter?.value
            ? likeAny(
                entity('legalEntity').property('cnaes'),
                filter?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              )
            : null,

          filter?.type === 'legalNatureId' && filter?.value
            ? equals(
                entity('legalEntity').property(`${filter?.type}`),
                string().value(`${filter?.value!}`),
              )
            : null,

          optionsBooleanValue.includes(`${filter?.type}`)
            ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
            : null,

          filter &&
          filter.type === 'attendance' &&
          filter.attendanceSource &&
          filter.attendanceSource !== '5'
            ? between(
                convert(entity('legalEntityAttendance').property('startDate'), date()),
                !filterStartDate
                  ? filter.attendanceSource === '3' || filter.attendanceSource === '4'
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(firstDayOfCurrentYearFormatted)
                  : string().value(filterStartDate),
                !filterFinalDate
                  ? entity('dataSource').property('endDate')
                  : string().value(filterFinalDate),
              )
            : null,

          filter?.type === 'attendance' && filter.attendanceSource === '5'
            ? between(
                entity('courseRegisterIndividual').property('registerDate'),
                !filterStartDate
                  ? string().value(firstDayOfCurrentYearFormatted)
                  : string().value(filterStartDate),
                !filterFinalDate
                  ? entity('dataSource').property('endDate')
                  : string().value(filterFinalDate),
              )
            : null,
        ].filter(truthy),
      )
      .limit(10000)
  )
}
