export const censusOptions = {
  censusUpdated: 'Dados da última atualização do IBGE',
}

export const profitsOptions = {
  profitsData: 'Dados do Brasil Mais e Radar ALI',
}

export const eadOptions = {
  all: 'Todos os cursos',
  portfolio: 'Todos os cursos do portfólio',
  individual: 'Cursos individuais do portfólio',
}

export const filterOptions = {
  size: 'Por porte',
  segment: 'Por segmento',
  cnaes: 'Por CNAE (incluindo secundários)',
  legalNatureId: 'Por natureza jurídica',
  attendance: 'Por atendimento',
  isSimples: 'SIMPLES',
}

export const attendanceFilterOption = {
  attendance: 'Por atendimento',
}

export const highlightOptions = {
  size: 'Porcentagem em um porte',
  segment: 'Porcentagem de cada segmento',
  cnaes: 'Porcentagem em CNAE (incluindo secundários)',
  legalNatureId: 'Porcentagem de uma natureza jurídica',
  attendance: 'Porcentagem de atendimento',
  isSimples: 'Porcentagem no sistema SIMPLES',
  area: 'Quantidade de empresas por km²',
  perCapta: 'Quantidade de empresas per capita',
  profits: 'Faturamento médio de empresas atendidas',
  cost: 'Custo médio de empresas atendidas',
  scoreOperationsManagement: 'Índice de gestão de operações',
  scoreTransformation: 'Índice de transformação digital',
  scoreIndicatorsManagement: 'Índice de gestão de indicadores',
  scoreInnovation: 'Índice de inovação',
  scoreMarketing: 'Índice de marketing',
  scoreSustainablePractices: 'Índice de práticas sustentáveis',
}

export const sizeOptions = {
  unknown: 'Não informado',
  micro: 'Micro empresa',
  isMei: 'MEI',
  small: 'Empresa de pequeno porte',
  other: 'Demais',
}
