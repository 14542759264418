/* globals caches */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMedia from 'react-use/lib/useMedia'
import BackIcon from '~/components/icons/arrows/24px_minimal-left.svg'
import {
  AppError,
  boolean,
  descending,
  entity,
  enumerated,
  equals,
  formatAsBrNumber,
  formatAsPercentage,
  like,
  number,
  query,
  some,
  string,
  truthy,
} from '~/prix'
import { Link, census, profits, scores } from './legalEntityGeoprocessingMapMenu.component'
import { colors } from '~/design'
import unaccent from '~/prix/functions/unaccent'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  Level,
  optionsBooleanValue,
  optionsSingleStringValue,
} from '../legalEntityGeoprocessingMapLevels.data'
import styled from 'styled-components'
import { DefinedOptionMerged } from './legalEntityGeoprocessingMapMenu.data'
import TopCard, { EntityDataProps } from '~/components/topCard'
import {
  LineHr,
  LoadingMessageWrapper,
  SectionTitle,
  Wrapper,
} from './legalEntityGeoprocessingCustomizedMapMenu.component'
import LegendContainer from '../legend/mapLegend.component'
import LoadingQueriesMessage from '~/prix/react/components/loadingQueriesMessage'
import { useParams } from 'react-router'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import { format, subDays, subYears } from 'date-fns'
import { isNullOrUndefined } from '~/prix/utils/empty'
import { useSearchParams } from 'react-router-dom'
import { eadOptions } from './legalEntityGeoprocessingMapMenuTypes'
import DateField from '~/prix/react/components/form/inputs/dateTimeField'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import useItems from '~/prix/react/hooks/items'
import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'
import InfoIcon from '~/components/icons/ui/16px_round-e-info.svg'
import useAdditionalInformation from '../additionalInformation/additionalInformation.hook'
import { formatDateUTC } from '~/prix/types/date'

const AlertInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f8fbff;
  color: #000;
  border: 1px solid #e7f2ff;
  border-radius: 4px;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .icon-wrapper {
    margin-top: 2px;
    flex-direction: column;
  }
  .span-wrapper {
    margin: auto 0;
    flex-direction: column;
    color: #212529;
    font-size: 13px;
  }
`

const Button = styled.button`
  margin-top: 15px;
  text-decoration: none;
  background-color: ${colors.sebraeBlue};
  border: 1px solid;
  color: #fff;
  padding: 10px;
  transition-duration: 0.5s;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.oceanBlue};
    color: #fff;
  }
`

export const TopCardIcon = styled.div`
  display: flex;
  max-width: 20px;
  align-items: center;

  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 175%;
    left: 125%;
    transform: translateX(-20%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
    font-size: 14px;
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-text ul {
    padding-left: 20px;
    margin: 10px 0 0;
  }

  .tooltip-text li {
    margin-bottom: 5px;
  }

  .top-card-info {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    color: #fff;
    justify-content: center;
    margin-bottom: 4px;

    @media (max-width: 768px) {
      margin-left: unset;
    }

    .description {
      font-size: 16px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
`

interface DefinedOptionMapMenuProps {
  isHeatmapEnabled: boolean
  title: string
  error?: AppError
  definedOption: DefinedOptionMerged
  countSum: number | null
  highlightSum: number | null
  onChangeFilter: (newFilter: FilterHighlight | null) => void
  onChangeHighlight: (newHighlight: FilterHighlight | null) => void
  filter: FilterHighlight | null
  highlight?: FilterHighlight | null
  isLoading: boolean
  toggleMenuVisibility: () => void
  maxValue: number | null
  minValue: number | null
  maxPercentColor: number | null
  minPercentColor: number | null
  handleOutOfRangeEntities: (entities: any) => void
  handleEntityData: (entities: any) => void
  isHighlightCensusEnabled: boolean
  isHighlightProfitsEnabled: boolean
  loadingMessage?: any
}

const AlertMessage = ({ type }: { type: string }) => {
  let text =
    type === 'census'
      ? 'Informações baseadas nos dados da última atualização do IBGE.'
      : type === 'isMei'
      ? 'Informações baseadas nos dados das últimas atualizações das fontes SEBRAE e Receita Federal.'
      : null

  return (
    <AlertInfo>
      <div className='icon-wrapper'>
        <AlertIcon fill={'#f1c40f'} width={18} height={18} />
      </div>
      <div className='span-wrapper'>{text}</div>
    </AlertInfo>
  )
}

export default function legalEntityGeoprocessingDefinedOptionMapMenu({
  onChangeFilter,
  onChangeHighlight,
  definedOption,
  isLoading,
  filter,
  highlight,
  title,
  countSum,
  highlightSum,
  isHeatmapEnabled,
  toggleMenuVisibility,
  maxValue,
  minValue,
  maxPercentColor,
  minPercentColor,
  handleOutOfRangeEntities,
  handleEntityData,
  isHighlightCensusEnabled,
  isHighlightProfitsEnabled,
  loadingMessage,
  error,
}: DefinedOptionMapMenuProps) {
  const isMobile = useMedia('(max-width: 768px)')
  const [level, setLevel] = useState<Level | null>(null)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [additionalInformation, setAdditionalInformation] = useState<any>(undefined)
  const [additionalInformationIsLoading, setAdditionalInformationIsLoading] = useState<boolean>()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [period, setPeriod] = useState<string>('')

  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  useEffect(() => {
    const definedOptionParam = searchParams.get('definedOption')

    if (startDate && endDate && definedOption.highlightType !== 'courseDropout') {
      handleOption([startDate, endDate], 'filter', 'attendance', undefined)
    }

    if (startDate && endDate && definedOption.highlightType === 'courseDropout') {
      handleOption([startDate, endDate], 'highlight', 'attendance', undefined)
    }

    if (!startDate && !endDate && definedOption.highlightType === 'isMei') {
      handleOption('isMei', 'highlight', 'size', undefined)
    }

    if (
      !startDate &&
      !endDate &&
      definedOption.highlightType === 'area' &&
      definedOptionParam === 'legalEntityByKm2'
    ) {
      handleOption('censusUpdated', 'highlight', 'area', undefined)
    }

    if (
      !startDate &&
      !endDate &&
      definedOption.highlightType === 'perCapta' &&
      definedOptionParam === 'legalEntityPerCapita'
    ) {
      handleOption('censusUpdated', 'highlight', 'perCapta', undefined)
    }
  }, [startDate, endDate, definedOption])

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: '30',
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params, definedOption])

  const eachSourceAttendanceDates = useItems(() => eachSourceAttendanceDatesQuery(), [isLoading], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const eachSourceAttendanceDatesFormatted: any = eachSourceAttendanceDates.items?.map(
    (item: any) => {
      const currentDateFormatted = formatDateUTC(new Date(), 'yyyy-MM-dd')
      let maxAttendanceDate = formatDateUTC(item.maxAttendanceDate, 'yyyy-MM-dd')
      let maxAttendanceDateThreshold = formatDateUTC(item.maxAttendanceDate, 'yyyy-MM-dd')
      const minAttendanceDate = formatDateUTC(item.minAttendanceDate, 'yyyy-MM-dd')
      if (currentDateFormatted === maxAttendanceDateThreshold) {
        maxAttendanceDateThreshold = formatDateUTC(
          subDays(new Date(item.maxAttendanceDate), 1),
          'yyyy-MM-dd',
        )
      }

      const currentDate = new Date()
      const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
      const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

      return {
        value: item.dataSourceId,
        label: item.dataSourceName,
        minAttendanceDate,
        maxAttendanceDate: maxAttendanceDateThreshold,
        startDate:
          item.dataSourceId === 3
            ? formatDateUTC(subYears(new Date(maxAttendanceDate), 1), 'yyyy-MM-dd')
            : firstDayOfCurrentYearFormatted,
        period: `Dados de ${formatDateUTC(
          new Date(minAttendanceDate),
          'dd/MM/yyyy',
        )} à ${formatDateUTC(new Date(maxAttendanceDate), 'dd/MM/yyyy')}`,
      }
    },
  )

  const eadSourceAttendanceDate = eachSourceAttendanceDatesFormatted?.filter((item: any) => {
    return item?.value === 5
  })

  const handleOption = useCallback(
    (
      value: string | string[] | null,
      mainOption: string,
      type: string,
      name?: string | undefined | null,
      attendanceSource?: string,
      eadOption?: string,
      courseProductCode?: string | null,
    ) => {
      // Filter
      if (mainOption === 'filter') {
        if (type) {
          if (value !== null && optionsSingleStringValue.includes(type)) {
            onChangeFilter({
              type,
              value: Array.isArray(value) ? value.join(',') : value,
              name: name ?? '',
              attendanceSource: attendanceSource ?? '',
            })
          }

          if (optionsBooleanValue.includes(type)) {
            onChangeFilter({ type, value: type })
          }
        }

        if (value === null) {
          onChangeFilter({ type, name: name ?? '', attendanceSource: attendanceSource ?? '' })
        }

        if (type === null) {
          onChangeFilter(null)
        }
      }

      // Highlight
      if (mainOption === 'highlight') {
        if (type) {
          if (value !== null && optionsSingleStringValue.includes(type)) {
            onChangeHighlight({
              type,
              value: Array.isArray(value) ? value.join(',') : value,
              name: name ?? '',
              attendanceSource: attendanceSource ?? '',
              eadOption: eadOption ?? '',
              courseProduct: courseProductCode ?? '',
            })
          }

          if (optionsBooleanValue.includes(type)) {
            onChangeHighlight({ type, value: type })
          }
        }

        if (type === null) {
          onChangeHighlight(null)
        }
      }
    },
    [onChangeFilter, onChangeHighlight, eachSourceAttendanceDatesFormatted],
  )

  useEffect(() => {
    if (definedOption.source && eachSourceAttendanceDatesFormatted) {
      let treatDataSourceId = definedOption.source

      if (definedOption.source === 1) {
        treatDataSourceId = 5
      }

      const dataSourceDates = eachSourceAttendanceDatesFormatted.filter(
        (item: { value: number }) => Number(item.value) == Number(treatDataSourceId),
      )
      if (dataSourceDates.length > 0 && !startDate) {
        const { maxAttendanceDate, startDate, period } = dataSourceDates[0]
        setEndDate(maxAttendanceDate)
        setStartDate(startDate)
        setPeriod(period)
      }
    }
  }, [eachSourceAttendanceDatesFormatted, definedOption.source, endDate, startDate])

  useEffect(() => {
    const definedOptionParam = searchParams.get('definedOption')

    if (
      definedOption.default === 'legalEntityAttendanceCourseDropout' &&
      eadSourceAttendanceDate?.length > 0 &&
      definedOption.highlightType !== null &&
      definedOption.default == definedOptionParam &&
      !startDate &&
      !endDate
    ) {
      setStartDate(eadSourceAttendanceDate[0].startDate)
      setEndDate(eadSourceAttendanceDate[0].maxAttendanceDate)
    }
  }, [definedOption, eadSourceAttendanceDate, startDate, endDate])

  const cnaeQueryFactory = useCallback((input: string) => {
    const inputWithWildcards = `%${input.replace(/\s/g, '%')}%`

    const cnaeNestSeparator = '>'
    return query('cnaeSection')
      .select({
        id: entity('cnaeSection').property('cnaes'),
        name: entity('cnaeSection').property('description'),
        subtitle: string().value('Seção'),
      })
      .where(
        like(
          unaccent(entity('cnaeSection').property('description')),
          unaccent(string().value(inputWithWildcards)),
        ),
      )
      .union(
        query('cnaeDivision')
          .select({
            id: entity('cnaeDivision').property('ibgeCode'),
            name: entity('cnaeDivision').property('description'),
            subtitle: string().value(`Seção ${cnaeNestSeparator} Divisão`),
          })
          .where(
            like(
              unaccent(entity('cnaeDivision').property('description')),
              unaccent(string().value(inputWithWildcards)),
            ),
          ),
        query('cnaeGroup')
          .select({
            id: entity('cnaeGroup').property('ibgeCode'),
            name: entity('cnaeGroup').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeGroup').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeGroup').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
        query('cnaeClass')
          .select({
            id: entity('cnaeClass').property('ibgeCode'),
            name: entity('cnaeClass').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo ${cnaeNestSeparator} Classe`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeClass').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeClass').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
      )
      .limit(21)
  }, [])

  const legalNatureQueryFactory = useCallback(
    (input: string) =>
      query('legalNature')
        .select({
          id: entity('legalNature').property('id'),
          name: entity('legalNature').property('description'),
        })
        .where(
          like(
            unaccent(entity('legalNature').property('description')),
            unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
          ),
        )
        .limit(10),
    [],
  )

  const courseProductQueryFactory = useCallback(
    (input: string) =>
      query('courseProduct')
        .select({
          productCode: entity('courseProduct').property('productCode'),
          portfolioSasCode: entity('courseProduct').property('portfolioSasCode'),
          productName: entity('courseProduct').property('productName'),
          cnpjCount: entity('courseProduct').property('cnpjCount'),
        })
        .where(
          ...[
            some(
              like(
                entity('courseProduct').property('portfolioSasCode'),
                string().value(`%${input.replace(/\s/g, '%')}%`),
              ),
              like(
                unaccent(entity('courseProduct').property('productName')),
                unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
              ),
              equals(
                entity('courseProduct').property('productCode'),
                number().value(Number(input)),
              ),
            ),
            equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),
          ].filter(truthy),
        )
        .order(descending('cnpjCount')),
    [],
  )

  const isHighlightCensus =
    highlight && census.includes(highlight?.type) && highlight?.value === 'censusUpdated'
  const isHighlightProfits =
    highlight && profits.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightScores =
    highlight && scores.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightIndicators = isHighlightProfits || isHighlightScores

  const censusMessage =
    highlight?.type === 'area'
      ? ' empresas por km²'
      : highlight?.type === 'perCapta'
      ? ' empresas per capita'
      : null

  const noCensusData =
    isHighlightCensus && (highlightSum === null || highlightSum === undefined || highlightSum === 0)
  const noDataHighlightMessage = isHighlightCensus
    ? 'Sem dados do IBGE para este nível'
    : isHighlightProfits || isHighlightScores
    ? 'Sem dados do Radar Ali e Brasil Mais para este nível'
    : ''

  const value =
    isHighlightCensus && countSum !== null && highlightSum !== null && countSum !== 0
      ? formatAsBrNumber(countSum / Number(highlightSum))
      : highlight &&
        !isNullOrUndefined(highlightSum) &&
        countSum !== null &&
        !isHighlightProfits &&
        !isHighlightScores
      ? `${countSum !== 0 ? formatAsPercentage(Number(highlightSum) / countSum) : 0}%`
      : highlight && !isNullOrUndefined(highlightSum) && (isHighlightProfits || isHighlightScores)
      ? formatAsBrNumber(Number(highlightSum))
      : countSum !== null && !isHighlightCensus && !isHighlightIndicators
      ? formatAsBrNumber(countSum, 0)
      : null

  const isLoadingMemo = useMemo(() => {
    const isLoadingDefault = isLoading && countSum === 0
    const isLoadingInfinity = isLoading === false && Number(countSum) === -Infinity

    return isLoadingDefault ? isLoadingDefault : isLoadingInfinity ? isLoadingInfinity : false
  }, [isLoading, countSum, highlight, loadingMessage, highlightSum])

  const result = useMemo(() => {
    const onlyTheseLevels =
      level &&
      (level.geo === 'city' ||
        level.geo === 'neighborhood' ||
        level.geo === 'street' ||
        level.geo === 'clusteredLegalEntity' ||
        level.geo === 'legalEntity')

    const noDataHighlight =
      highlightSum === null &&
      onlyTheseLevels &&
      (isHighlightCensus || isHighlightProfits || isHighlightScores)
        ? noDataHighlightMessage
        : null
    const minValueHighlight = value === '' && highlight !== null ? '0%' : null
    const emptyValueNullableHighlight =
      value === '' && highlight === null && highlightSum ? Number(value) : null
    const isProfitsValue = isHighlightProfits && noDataHighlight === null ? `R$ ${value}` : null
    const isScoresValue = isHighlightScores && noDataHighlight === null ? `Média: ${value}` : null

    return isLoading === false
      ? noDataHighlight
        ? noDataHighlight
        : minValueHighlight
        ? minValueHighlight
        : emptyValueNullableHighlight
        ? emptyValueNullableHighlight
        : isProfitsValue
        ? isProfitsValue
        : isScoresValue
        ? isScoresValue
        : value
      : ''
  }, [
    level,
    isHighlightCensusEnabled,
    isHighlightCensus,
    noCensusData,
    isHighlightProfitsEnabled,
    isHighlightIndicators,
    value,
    highlight,
    isHighlightProfits,
    isHighlightScores,
    highlightSum,
    noDataHighlightMessage,
    isLoading,
  ])

  const resultSuffix = useMemo(() => {
    const highlightDefaultSuffix =
      highlight &&
      highlightSum !== null &&
      highlight.type !== 'perCapta' &&
      highlight.type !== 'area' &&
      highlight.type !== 'courseDropout' &&
      !isHighlightProfits &&
      !isHighlightScores
        ? `${definedOption.highlightType === 'isMei' ? '%' : ''} das empresas`
        : null
    const highlightScoresSuffix =
      isHighlightScores && highlightSum !== null && value ? ' de 5' : null
    const highlightCensusSuffix =
      highlight !== null && isHighlightCensus ? ` ${censusMessage}` : null
    const legalEntitySuffix =
      !highlight || (highlight && highlight.value === null)
        ? Number(value) === 1
          ? ' empresa'
          : ' empresas'
        : null
    const highlightCourseDropout =
      highlight && highlight.type === 'courseDropout' ? '% de alunos' : null
    const definedOptionIndexSuffix =
      definedOption && definedOption.type === 'legalEntityAttendanceIndex' ? '%' : null

    return (
      definedOptionIndexSuffix ||
      highlightDefaultSuffix ||
      highlightScoresSuffix ||
      highlightCensusSuffix ||
      legalEntitySuffix ||
      highlightCourseDropout
    )
  }, [
    highlight,
    highlightSum,
    isHighlightProfits,
    isHighlightScores,
    censusMessage,
    noCensusData,
    value,
  ])

  const hideAdditionalInformationCondition = useMemo(() => {
    const highlightPerCapta =
      highlight &&
      highlight.type === 'perCapta' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightArea =
      highlight &&
      highlight.type === 'area' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightValue =
      highlight && highlight.type !== 'perCapta' && highlight.type !== 'area' && highlight.value
        ? true
        : false
    const highlightSumNullable =
      highlight &&
      (highlight.type === 'perCapta' || highlight.type === 'area') &&
      highlight.value === 'censusUpdated' &&
      highlightSum === null &&
      level &&
      level.geo === 'city'
        ? true
        : false
    const definedOptionIndexValue = definedOption.type === 'legalEntityAttendanceIndex'

    return (
      highlightPerCapta ||
      highlightArea ||
      highlightValue ||
      highlightSumNullable ||
      definedOptionIndexValue
    )
  }, [highlight, highlightSum, isHighlightScores, isHighlightProfits, level, value, definedOption])

  const calculateIndexValue = (highlightSum, countSum, additionalInformation) => {
    if (highlightSum === null || highlightSum === undefined) {
      return countSum !== null ? formatAsBrNumber(countSum, 0) : null
    }

    if (countSum === null || countSum === 0) {
      return '0%'
    }

    const [firstValue, secondValue] = additionalInformation
      ? additionalInformation.split(',').map(value => Number(value.trim().replace(/[\.,]/g, '')))
      : [0, 0]

    const adjustedHighlightSum = Number(highlightSum.toString().replace(/[\.,]/g, '')) + firstValue
    const adjustedCountSum = Number(countSum) + secondValue

    const percentage = adjustedHighlightSum / adjustedCountSum

    if (percentage > 1) {
      return formatAsPercentage(percentage)
    }

    if (percentage < 0.001) {
      return '< 0.001%'
    }

    return (percentage * 100).toFixed(2).replace('.', ',') + '%'
  }

  const indexValue = calculateIndexValue(highlightSum, countSum, additionalInformation)

  const entityData: EntityDataProps = useMemo(() => {
    if (definedOption.highlightType !== 'area' && definedOption.highlightType !== 'perCapta') {
      if (isLoadingMemo || isLoading || additionalInformationIsLoading) {
        handleEntityData('Carregando...')
        return 'Carregando...'
      }
    } else {
      if (isLoadingMemo || isLoading) {
        handleEntityData('Carregando...')
        return 'Carregando...'
      }
    }

    if ((!isMobile && result === null) || resultSuffix === null) {
      let result = 'Sem dados disponíveis'
      handleEntityData(result)
      return result
    }

    if (definedOption.highlightType === 'courseDropout') {
      let result = `${formatAsBrNumber(Number(highlightSum), 0)} de ${formatAsBrNumber(
        Number(countSum),
        0,
      )} empresas (${indexValue})`

      handleEntityData(result)
      return result
    }

    if (
      definedOption.type === 'legalEntityAttendanceIndex' ||
      definedOption.highlightType === 'isMei'
    ) {
      const [firstValue, secondValue] = additionalInformation
        ? additionalInformation.split(',').map(value => Number(value.trim().replace(/[\.,]/g, '')))
        : [0, 0]

      const sanitizedHighlightSum = highlightSum
        ? Number(highlightSum.toString().replace(/[\.,]/g, ''))
        : 0

      const resultWithAdditionalInformation = firstValue
        ? sanitizedHighlightSum + firstValue
        : sanitizedHighlightSum

      const adjustedCountSum = secondValue ? Number(countSum) + secondValue : Number(countSum)

      const suffix =
        highlightSum && indexValue
          ? `de ${formatAsBrNumber(adjustedCountSum, 0)} empresas (${indexValue})`
          : ''

      handleEntityData({
        selectedEntities: resultWithAdditionalInformation,
        availableEntities: highlightSum,
        suffix,
        outOfRangeTotal: additionalInformation ?? undefined,
        definitionType: definedOption.type,
        legalEntityActiveCount: Number(countSum),
      })

      return {
        selectedEntities: resultWithAdditionalInformation,
        availableEntities: highlightSum,
        suffix,
        outOfRangeTotal: additionalInformation ?? undefined,
        definitionType: definedOption.type,
        legalEntityActiveCount: Number(countSum),
      }
    }

    if (
      definedOption.type !== 'legalEntityAttendanceIndex' &&
      definedOption.highlightType !== 'isMei'
    ) {
      const sanitizedResult = result ? Number(result.toString().replace(/[\.,]/g, '')) : 0
      const sanitizedAdditionalInformation = additionalInformation
        ? Number(additionalInformation.toString().replace(/[\.,]/g, ''))
        : 0

      const resultWithAdditionalInformation = sanitizedResult + sanitizedAdditionalInformation

      handleEntityData({
        selectedEntities: resultWithAdditionalInformation,
        availableEntities: result,
        suffix: resultSuffix ?? '',
        outOfRangeTotal: additionalInformation ?? '',
        definitionType:
          definedOption.highlightType === 'area' || definedOption.highlightType === 'perCapta'
            ? 'census'
            : definedOption.type,
      })
      return {
        selectedEntities: resultWithAdditionalInformation,
        availableEntities: result,
        suffix: resultSuffix ?? '',
        outOfRangeTotal: additionalInformation ?? '',
        definitionType:
          definedOption.highlightType === 'area' || definedOption.highlightType === 'perCapta'
            ? 'census'
            : definedOption.type,
      }
    }
  }, [
    result,
    additionalInformation,
    additionalInformationIsLoading,
    resultSuffix,
    isMobile,
    definedOption,
    isLoadingMemo,
    isLoading,
    highlightSum,
    countSum,
  ])

  useAdditionalInformation(
    isLoading === false && definedOption.highlightType === 'isMei'
      ? isLoading
      : isLoading === false && !filter?.value
      ? true
      : isLoading,
    setAdditionalInformation,
    setAdditionalInformationIsLoading,
  )

  useEffect(() => {
    if (additionalInformation) {
      handleOutOfRangeEntities(additionalInformation)
    }
  }, [additionalInformation])

  return (
    <React.Fragment>
      <Wrapper>
        {isMobile ? (
          <TopCard title={title} definedOption={definedOption} />
        ) : (
          <div className='button-wrapper'>
            <Link href='/app/map'>
              <BackIcon width={10} height={10} />
              <span>Voltar</span>
            </Link>
          </div>
        )}

        <div className='containers'>
          {definedOption.highlightValue === 'censusUpdated' && !isMobile ? (
            <AlertMessage type='census' />
          ) : definedOption.highlightType === 'isMei' && !isMobile ? (
            <AlertMessage type='isMei' />
          ) : null}

          {isMobile && definedOption.highlightType ? (
            <fieldset
              className='section-container preset-mobile'
              style={{ borderTopRightRadius: '12px', borderTopLeftRadius: '12px' }}
            >
              {!error ? (
                <div className='item-container results-container'>
                  {isLoadingMemo || isLoading ? (
                    isMobile && loadingMessage !== undefined ? (
                      <>
                        <label>Total da seleção</label>
                        <output>
                          <LoadingMessageWrapper>
                            <LoadingQueriesMessage queries={loadingMessage} />
                          </LoadingMessageWrapper>
                        </output>
                      </>
                    ) : (
                      <output>{entityData.selectedEntities}</output>
                    )
                  ) : result === null ? (
                    <>
                      <label>Total da seleção</label>
                      <output>Sem dados disponíveis</output>
                    </>
                  ) : (
                    <>
                      <label>Total da seleção</label>
                      <output>
                        {result}
                        {resultSuffix}
                      </output>
                    </>
                  )}

                  {!isLoading && isMobile && (
                    <Button onClick={toggleMenuVisibility}>Ver Mapa</Button>
                  )}
                </div>
              ) : null}

              {(isHighlightScores || isHighlightProfits) && !isLoading && (
                <div className='alert-info' role='alert'>
                  Informações baseadas em empresas que já foram atendidas pelo Brasil Mais ou Radar
                  ALI.
                  <AlertIcon fill={'#f1c40f'} width={18} height={18} />
                </div>
              )}
            </fieldset>
          ) : null}

          {!definedOption.highlightType && startDate ? (
            <div>
              <SectionTitle>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span>Filtros</span>
                  </div>
                </div>
              </SectionTitle>
              <fieldset className='section-container'>
                {definedOption.searchType === 'dateRange' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <div className='date-wrapper'>
                        <DateField
                          id='start-date-field'
                          value={startDate}
                          label='Data inicial'
                          onDirectChange={date => setStartDate(format(date, 'yyyy-MM-dd'))}
                          placeholder='Início'
                          meta={{
                            maxDate: new Date(),
                            minDate: subYears(new Date(), 10),
                          }}
                          isRequired
                        />
                        <DateField
                          id='end-date-field'
                          value={endDate}
                          label='Data final'
                          onDirectChange={date => setEndDate(format(date, 'yyyy-MM-dd'))}
                          placeholder='Final'
                          meta={{
                            maxDate: new Date(),
                            minDate: subYears(new Date(), 10),
                          }}
                          isRequired
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {definedOption.searchType === 'cnae' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <label>Filtrar por CNAE</label>
                      <div style={{ marginTop: '5px' }}>
                        <SelectFromQuery
                          queryFactory={cnaeQueryFactory}
                          idProperty='id'
                          labelProperty='name'
                          preLoad
                          value={
                            filter && filter.value
                              ? { id: String(filter.value), name: String(filter.name) }
                              : highlight && highlight.value
                              ? { id: highlight.value, name: highlight.name! }
                              : undefined
                          }
                          onDirectChange={value =>
                            handleOption(
                              value?.id ? String(value.id) : null,
                              'filter',
                              'cnaes',
                              value?.name ? String(value.name) : undefined,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {definedOption.searchType === 'legalNature' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <label>Filtrar por natureza jurídica</label>
                      <div style={{ marginTop: '5px' }}>
                        <SelectFromQuery
                          queryFactory={legalNatureQueryFactory}
                          idProperty='id'
                          labelProperty='name'
                          preLoad
                          value={
                            filter && filter.value
                              ? { id: String(filter.value), name: String(filter.name) }
                              : undefined
                          }
                          onDirectChange={value =>
                            handleOption(
                              value?.id ? String(value.id) : null,
                              'filter',
                              'legalNatureId',
                              value?.name ? String(value.name) : undefined,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {!error && isMobile && (
                  <div>
                    <LineHr />
                  </div>
                )}

                {!error ? (
                  <div className='item-container results-container'>
                    {isLoadingMemo && isLoading ? (
                      isMobile && loadingMessage !== undefined ? (
                        <>
                          <label>Total da seleção</label>
                          <output>
                            <LoadingMessageWrapper>
                              <LoadingQueriesMessage queries={loadingMessage} />
                            </LoadingMessageWrapper>
                          </output>
                        </>
                      ) : (
                        <output>{entityData.selectedEntities}</output>
                      )
                    ) : null}

                    {isMobile && entityData === null ? (
                      <>
                        <label>Total da seleção</label>
                        <output>Sem dados disponíveis</output>
                      </>
                    ) : null}

                    {/* entityData?.outOfRangeTotal === 'Carregando...' ||
                  entityData?.selectedEntities === undefined ||
                  Number.isNaN(Number(entityData?.selectedEntities))
                    ? 'Carregando...' */}

                    {isMobile && entityData.selectedEntities !== null ? (
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '25px',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <TopCardIcon>
                            <div className='tooltip-container'>
                              <InfoIcon width={16} height={16} fill={colors.sebraeBlue} />
                              <div className='tooltip-text'>
                                A seleção total inclui:
                                <ul>
                                  <li>
                                    <strong>
                                      {entityData?.availableEntities?.toLocaleString('pt-BR')}
                                    </strong>{' '}
                                    empresas{' '}
                                    {entityData?.definitionType === 'customized' ? 'ativas' : null}{' '}
                                    disponíveis no mapa.
                                  </li>
                                  {Number(entityData?.outOfRangeTotal) > 0 ? (
                                    <li>
                                      <strong>{entityData?.outOfRangeTotal}</strong> empresas{' '}
                                      {entityData?.definitionType === 'customized'
                                        ? 'ativas'
                                        : null}{' '}
                                      indisponíveis no mapa por endereçamento limitado.
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </TopCardIcon>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            width: '50px',
                            flexDirection: 'column',
                          }}
                        >
                          <label>Total da seleção</label>
                          {entityData?.outOfRangeTotal === 'Carregando...' ||
                          entityData?.selectedEntities === undefined ||
                          Number.isNaN(Number(entityData?.selectedEntities)) ? (
                            'Carregando...'
                          ) : (
                            <span>
                              {`${entityData.selectedEntities?.toLocaleString('pt-BR')} ${
                                entityData?.suffix
                              }`}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : null}

                    {!isLoading && isMobile && (
                      <Button onClick={toggleMenuVisibility}>Ver Mapa</Button>
                    )}
                  </div>
                ) : null}

                {(isHighlightScores || isHighlightProfits) && !isLoading && (
                  <div className='alert-info' role='alert'>
                    Informações baseadas em empresas que já foram atendidas pelo Brasil Mais ou
                    Radar ALI.
                    <AlertIcon fill={'#f1c40f'} width={18} height={18} />
                  </div>
                )}
              </fieldset>
            </div>
          ) : null}

          {definedOption.highlightType === 'courseDropout' && (
            <div>
              <SectionTitle>
                <span>Filtros</span>
              </SectionTitle>
              <fieldset className='section-container'>
                <div className='item-container'>
                  <label>Nível</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      meta={{ isRequired: true }}
                      fieldSchema={enumerated(eadOptions)}
                      value={highlight?.eadOption || 'all'}
                      onDirectChange={(value: any) =>
                        handleOption(
                          highlight?.value ? String(highlight?.value) : null,
                          'highlight',
                          'courseDropout',
                          undefined,
                          '5',
                          value,
                          undefined,
                        )
                      }
                    />
                  </div>
                </div>

                {highlight?.eadOption === 'individual' && (
                  <div className='item-container'>
                    <div style={{ marginTop: '2px' }}></div>
                    <label>Curso</label>
                    <div style={{ marginTop: '4px' }}>
                      <SelectFromQuery
                        queryFactory={courseProductQueryFactory}
                        idProperty='productCode'
                        labelProperty='productName'
                        preLoad
                        value={
                          highlight?.courseProduct
                            ? {
                                productCode: highlight?.courseProduct,
                                productName: highlight?.name,
                              }
                            : undefined
                        }
                        onDirectChange={value => {
                          handleOption(
                            highlight?.eadOption ? highlight?.eadOption : null,
                            'highlight',
                            'courseDropout',
                            value?.productName ? String(value?.productName) : undefined,
                            '5',
                            'individual',
                            value?.productCode ? String(value?.productCode) : undefined,
                          )
                        }}
                      />
                    </div>
                  </div>
                )}

                {startDate ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <div className='date-wrapper'>
                        <DateField
                          id='start-date-field'
                          value={startDate}
                          label='Data inicial'
                          onDirectChange={date => setStartDate(format(date, 'yyyy-MM-dd'))}
                          placeholder='Início'
                          meta={{
                            maxDate: new Date(),
                            minDate: subYears(new Date(), 10),
                          }}
                          isRequired
                        />
                        <DateField
                          id='end-date-field'
                          value={endDate}
                          label='Data final'
                          onDirectChange={date => setEndDate(format(date, 'yyyy-MM-dd'))}
                          placeholder='Final'
                          meta={{
                            maxDate: new Date(),
                            minDate: subYears(new Date(), 10),
                          }}
                          isRequired
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </fieldset>
            </div>
          )}
        </div>

        {definedOption.filterValue === 'censusUpdated' && isMobile ? (
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <AlertMessage type='census' />
          </div>
        ) : definedOption.highlightType === 'isMei' && isMobile ? (
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <AlertMessage type='isMei' />
          </div>
        ) : null}

        {isHighlightCensusEnabled && !error && isHighlightProfitsEnabled && (
          <LegendContainer
            isHeatmapEnabled={
              isHeatmapEnabled || searchParams.get('visualizationType') === 'heatmap'
            }
            maxValue={maxValue ? maxValue : null}
            minValue={minValue ? minValue : null}
            isLoadingLegend={isLoading}
            maxPercentColor={minPercentColor ? maxPercentColor : null}
            minPercentColor={minPercentColor ? minPercentColor : null}
            highlight={highlight}
            suffix={resultSuffix}
          />
        )}
      </Wrapper>
    </React.Fragment>
  )
}
