/* globals caches */
import { format, subDays, subYears } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import { colors } from '~/design'
import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'
import AttendanceField from '~/packages/legalEntityAttendance/attendanceField'
import {
  AppError,
  descending,
  entity,
  enumerated,
  formatAsBrNumber,
  formatAsPercentage,
  like,
  query,
  string,
  truthy,
  number,
  some,
  equals,
  boolean,
} from '~/prix'
import unaccent from '~/prix/functions/unaccent'
import { QueryBase } from '~/prix/query'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import LoadingQueriesMessage from '~/prix/react/components/loadingQueriesMessage'
import useItems from '~/prix/react/hooks/items'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  Level,
  optionsBooleanValue,
  optionsSingleStringValue,
} from '../legalEntityGeoprocessingMapLevels.data'
import LegendContainer from '../legend/mapLegend.component'
import { isNullOrUndefined } from '~/prix/utils/empty'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import {
  censusOptions,
  eadOptions,
  filterOptions,
  highlightOptions,
  profitsOptions,
  sizeOptions,
} from './legalEntityGeoprocessingMapMenuTypes'
import TopCard from '~/components/topCard'
import InfoIcon from '~/components/icons/ui/16px_round-e-info.svg'
import { Link } from './legalEntityGeoprocessingMapMenu.component'
import BackIcon from '~/components/icons/arrows/24px_minimal-left.svg'
import useAPI from '~/prix/react/hooks/api'
import DateField from '~/prix/react/components/form/inputs/dateTimeField'
import { TopCardIcon } from './legalEntityGeoprocessingDefinedOptionMapMenu.component'
import useAdditionalInformation from '../additionalInformation/additionalInformation.hook'
import { formatDateUTC } from '~/prix/types/date'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;

  .button-wrapper {
    display: flex;
    flex: 1;
    margin-top: 10px;
    margin-left: 10px;
    max-height: 20px;

    a {
      text-align: left;
      align-self: center;
    }

    svg {
      fill: ${colors.sebraeBlue};
    }
  }

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px 8px;

    fieldset.section-container {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding: 15px 15px 30px 15px;
      margin-bottom: 10px;
      border: solid 1px rgba(221, 221, 221, 1);
      border-radius: 0px 0px 12px 12px;
      box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
      min-width: 0;

      .item-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 13px;

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
          background-color: #fff;
          width: fit-content;
          padding: 0 2px;
        }

        &.results-container {
          margin-top: 0;
          margin-bottom: 10px;

          label {
            margin-bottom: 0;
            margin-left: 0;
            padding: 0;
          }

          output {
            margin-top: 2.5px;
          }
        }

        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
            margin-bottom: 7.5px;
          }
        }
      }
      .item-additional-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        small {
          margin-top: 2.5px;
        }

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
          margin-top: 10px;
        }
        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
          }
        }
      }
    }

    .line-container {
      margin: 5px 0px;
    }

    .line-container-label {
      width: 56px;
    }

    .line {
      display: block;
      height: 1px;
      background: transparent;
      width: 100%;
      border: none;
      margin-top: 5px;
      margin-bottom: 5px;
      border-top: solid 1px rgba(221, 221, 221, 1);
    }

    .legend-container {
      margin: 16px 16px 0;
      height: 120px;

      label {
        color: ${colors.sebraeBlue};
        font-weight: normal;
      }

      div {
        display: flex;

        .column-background {
          width: 25px;
          flex-direction: column;
          align-items: left;
          margin-right: 10px;
        }

        .column {
          flex: 1;
          flex-direction: column;
          height: 65px;
        }

        .row {
          flex-direction: row;
          align-items: center;
          padding: 5px;

          .row-span {
            font-size: 14px;
            color: #363636;
            font-weight: normal;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .alert-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: 16px 8px 10px 8px;
    color: #212529;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    padding: 0;

    .containers {
      margin-top: -32px;
      padding: 0 32px;
      z-index: 5;
    }

    .alert-info,
    .legend-container {
      padding: 0 32px;
    }
  }
`

export const SectionTitle = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--p-action, #0f438a);
  display: flex;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  margin: 0 2px;

  span {
    color: #fff;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }

  .top-card-icon {
    display: flex;
    /* max-width: 200px; */
    align-items: center;
    width: 8%;

    .tooltip-container {
      position: relative;
      display: inline-block;
    }

    .tooltip-text {
      visibility: hidden;
      width: 275px;
      background-color: #333;
      color: #fff;
      text-align: left;
      border-radius: 5px;
      padding: 10px;
      position: absolute;
      top: 125%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 999999999;
      font-size: 14px;
    }

    .tooltip-container:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }

    .tooltip-text ul {
      padding-left: 20px;
    }

    .tooltip-text li {
      margin-bottom: 5px;
    }
  }

  .info-icon-container {
    position: relative;
    display: inline-block;
  }

  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    display: none;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 15px 5px 15px 5px;

    .ball {
      width: 10px;
      height: 10px;
      background-color: #4285f4;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;
      margin-left: 10px;
    }
  }

  .info-icon-container:hover .tooltip {
    display: block;
  }
`

export const LoadingMessageWrapper = styled.div`
  font-size: 80%;
  div {
    align-items: unset;
    justify-content: unset;
  }
`

const Button = styled.button`
  margin-top: 15px;
  text-decoration: none;
  background-color: ${colors.sebraeBlue};
  border: 1px solid;
  color: #fff;
  padding: 10px;
  transition-duration: 0.5s;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.oceanBlue};
    color: #fff;
  }
`

const MenuSection = styled.p`
  color: var(--p-default, #005eb8);
  font-size: 16px;
  line-height: 23.3px;
  font-weight: 700;
  margin: 0;
`

export const LineHr = styled.hr`
  margin-top: 15px;
  display: block;
  height: 1px;
  background: transparent;
  width: 90%;
  border: none;
`

interface MapMenu {
  onChangeFilter: (newFilter: FilterHighlight | null) => void
  onChangeHighlight: (newHighlight: FilterHighlight | null) => void
  filter: FilterHighlight | null
  highlight?: FilterHighlight | null
  countSum: number | null
  highlightSum?: string | number | true | object | null
  isLoading: boolean
  isHeatmapEnabled: boolean
  isHighlightCensusEnabled: boolean
  isHighlightProfitsEnabled: boolean
  loadingMessage?: QueryBase[]
  toggleMenuVisibility: () => void
  error?: AppError
  maxValue: number | null
  minValue: number | null
  minPercentColor: number | null
  maxPercentColor: number | null
  handleOutOfRangeEntities: (setOutOfRangeEntities: any) => void
  handleEntityData: (setEntityData: any) => void
}

export const scores = [
  'scoreOperationsManagement',
  'scoreTransformation',
  'scoreIndicatorsManagement',
  'scoreInnovation',
  'scoreMarketing',
  'scoreSustainablePractices',
]
export const census = ['area', 'perCapta']
export const profits = ['profits', 'cost']

export default function MapMenu({
  onChangeFilter,
  filter,
  onChangeHighlight,
  highlight,
  countSum,
  highlightSum,
  isLoading,
  isHeatmapEnabled,
  isHighlightCensusEnabled,
  isHighlightProfitsEnabled,
  loadingMessage,
  toggleMenuVisibility,
  error,
  maxValue,
  minValue,
  maxPercentColor,
  minPercentColor,
  handleOutOfRangeEntities,
  handleEntityData,
}: MapMenu) {
  const [level, setLevel] = useState<Level | null>(null)
  const [attendanceSourceActive, setAttendanceSourceActive] = useState<string | undefined>(
    undefined,
  )
  const [attendanceSourceHighlightActive, setAttendanceSourceHighlightActive] = useState<
    string | undefined
  >(undefined)
  const [attendanceEadOptionActive, setAttendanceEadOptionActive] = useState<string | undefined>(
    undefined,
  )
  const [attendanceEadOptionHighlightActive, setAttendanceEadOptionHighlightActive] = useState<
    string | undefined
  >(undefined)
  const [courseProductActive, setCourseProductActive] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [courseProductHighlightActive, setCourseProductHighlightActive] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [additionalInformation, setAdditionalInformation] = useState<any>(undefined)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [startDateHighlight, setStartDateHighlight] = useState<string>('')
  const [endDateHighlight, setEndDateHighlight] = useState<string>('')
  const [period, setPeriod] = useState<string>('')
  const [additionalInformationIsLoading, setAdditionalInformationIsLoading] = useState<boolean>()

  useEffect(() => {
    if (startDate && endDate && attendanceSourceActive) {
      handleOption([startDate, endDate], 'filter', 'attendance', undefined, attendanceSourceActive)
    }
  }, [startDate, endDate, attendanceSourceActive])

  useEffect(() => {
    if (startDateHighlight && endDateHighlight && attendanceSourceHighlightActive) {
      handleOption(
        [startDateHighlight, endDateHighlight],
        'highlight',
        'attendance',
        undefined,
        attendanceSourceHighlightActive,
      )
    }
  }, [startDateHighlight, endDateHighlight, attendanceSourceHighlightActive])

  const isMobile = useMedia('(max-width: 768px)')
  const params = useParams()
  const { context } = useAPI()

  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item =>
          item === 'systemAdministrator' ||
          item === 'administrator' ||
          item === 'manager' ||
          item === 'appSebraeManager',
      )
    : false

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: '30',
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params])

  const eachSourceAttendanceDates = useItems(() => eachSourceAttendanceDatesQuery(), [isLoading], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const eachSourceAttendanceDatesFormatted: any = eachSourceAttendanceDates.items
    ?.filter((item: any) => {
      if (context.user?.roles?.includes('appSebraeManager')) {
        return item.dataSourceId === 6
      }
      return true
    })
    .map((item: any) => {
      const currentDateFormatted = formatDateUTC(new Date(), 'yyyy-MM-dd')
      let maxAttendanceDate = formatDateUTC(item.maxAttendanceDate, 'yyyy-MM-dd')
      let maxAttendanceDateThreshold = formatDateUTC(item.maxAttendanceDate, 'yyyy-MM-dd')
      const minAttendanceDate = formatDateUTC(item.minAttendanceDate, 'yyyy-MM-dd')
      if (currentDateFormatted === maxAttendanceDateThreshold) {
        maxAttendanceDateThreshold = formatDateUTC(
          subDays(new Date(item.maxAttendanceDate), 1),
          'yyyy-MM-dd',
        )
      }

      const currentDate = new Date()
      const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
      const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

      return {
        value: item.dataSourceId,
        label: item.dataSourceName,
        minAttendanceDate,
        maxAttendanceDate: maxAttendanceDateThreshold,
        startDate:
          item.dataSourceId === 3 || item.dataSourceId === 4
            ? formatDateUTC(subYears(new Date(maxAttendanceDate), 1), 'yyyy-MM-dd')
            : firstDayOfCurrentYearFormatted,
        period: `Dados de ${formatDateUTC(
          new Date(minAttendanceDate),
          'dd/MM/yyyy',
        )} à ${formatDateUTC(new Date(maxAttendanceDate), 'dd/MM/yyyy')}`,
      }
    })

  useEffect(() => {
    if (eachSourceAttendanceDatesFormatted && filter?.attendanceSource && !startDate && !endDate) {
      eachSourceAttendanceDatesFormatted.map((item: any) => {
        if (item?.value === Number(filter?.attendanceSource)) {
          const endDate = item.maxAttendanceDate ? item.maxAttendanceDate : null
          setEndDate(endDate)
          const startDate = item.startDate ? item.startDate : null
          setStartDate(startDate)
          setPeriod(item.period)
        }
      })
    }

    if (
      eachSourceAttendanceDatesFormatted &&
      highlight?.attendanceSource &&
      !startDateHighlight &&
      !endDateHighlight
    ) {
      eachSourceAttendanceDatesFormatted.map((item: any) => {
        if (item?.value === Number(highlight?.attendanceSource)) {
          const endDate = item.maxAttendanceDate ? item.maxAttendanceDate : null
          setEndDateHighlight(endDate)
          const startDate = item.startDate ? item.startDate : null
          setStartDateHighlight(startDate)
          setPeriod(item.period)
        }
      })
    }
  }, [eachSourceAttendanceDatesFormatted, filter?.attendanceSource, highlight?.attendanceSource])

  const cnaeQueryFactory = useCallback((input: string) => {
    const inputWithWildcards = `%${input.replace(/\s/g, '%')}%`

    const cnaeNestSeparator = '>'
    return query('cnaeSection')
      .select({
        id: entity('cnaeSection').property('cnaes'),
        name: entity('cnaeSection').property('description'),
        subtitle: string().value('Seção'),
      })
      .where(
        like(
          unaccent(entity('cnaeSection').property('description')),
          unaccent(string().value(inputWithWildcards)),
        ),
      )
      .union(
        query('cnaeDivision')
          .select({
            id: entity('cnaeDivision').property('ibgeCode'),
            name: entity('cnaeDivision').property('description'),
            subtitle: string().value(`Seção ${cnaeNestSeparator} Divisão`),
          })
          .where(
            like(
              unaccent(entity('cnaeDivision').property('description')),
              unaccent(string().value(inputWithWildcards)),
            ),
          ),
        query('cnaeGroup')
          .select({
            id: entity('cnaeGroup').property('ibgeCode'),
            name: entity('cnaeGroup').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeGroup').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeGroup').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
        query('cnaeClass')
          .select({
            id: entity('cnaeClass').property('ibgeCode'),
            name: entity('cnaeClass').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo ${cnaeNestSeparator} Classe`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeClass').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeClass').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
      )
      .limit(21)
  }, [])

  const legalNatureQueryFactory = useCallback(
    (input: string) =>
      query('legalNature')
        .select({
          id: entity('legalNature').property('id'),
          name: entity('legalNature').property('description'),
        })
        .where(
          like(
            unaccent(entity('legalNature').property('description')),
            unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
          ),
        )
        .limit(10),
    [],
  )

  const segmentQueryFactory = useCallback(
    (input: string) =>
      query('segment')
        .select({
          id: entity('segment').property('cnaes'),
          name: entity('segment').property('description'),
        })
        .where(
          like(
            unaccent(entity('segment').property('description')),
            unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
          ),
        )
        .limit(74),
    [],
  )

  const courseProductQueryFactory = useCallback(
    (input: string) =>
      query('courseProduct')
        .select({
          productCode: entity('courseProduct').property('productCode'),
          portfolioSasCode: entity('courseProduct').property('portfolioSasCode'),
          productName: entity('courseProduct').property('productName'),
          cnpjCount: entity('courseProduct').property('cnpjCount'),
        })
        .where(
          ...[
            some(
              like(
                entity('courseProduct').property('portfolioSasCode'),
                string().value(`%${input.replace(/\s/g, '%')}%`),
              ),
              like(
                unaccent(entity('courseProduct').property('productName')),
                unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
              ),
              equals(
                entity('courseProduct').property('productCode'),
                number().value(Number(input)),
              ),
            ),
            equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),
          ].filter(truthy),
        )
        .order(descending('cnpjCount')),
    [],
  )

  useEffect(() => {
    if (filter?.attendanceSource !== attendanceSourceActive) {
      setStartDate('')
      setEndDate('')
    }
  }, [filter?.attendanceSource, attendanceSourceActive])

  const handleOption = useCallback(
    (
      value: string | string[] | null,
      mainOption: string,
      type: string,
      name?: string | undefined,
      attendanceSource?: string,
      eadOption?: string,
      courseProductCode?: string | null,
    ) => {
      // filter
      if (mainOption === 'filter') {
        if (type) {
          if (value !== null && optionsSingleStringValue.includes(type) && value) {
            onChangeFilter({
              type,
              value: Array.isArray(value) ? value.join(',') : value,
              name: name ?? '',
              attendanceSource: courseProductCode ? '5' : attendanceSource ? attendanceSource : '',
              eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
              courseProduct: courseProductCode ?? '',
            })

            return
          }

          if (optionsBooleanValue.includes(type)) {
            onChangeFilter({ type, value: type })

            return
          }
        }

        if (value === null && eadOption === undefined) {
          onChangeFilter({
            value: attendanceSource ? [startDate, endDate] : null,
            type,
            name: name ?? '',
            attendanceSource: attendanceSource ?? '',
            eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
            courseProduct: courseProductCode ?? '',
          })
          setAttendanceSourceActive(attendanceSource ? attendanceSource : '')
          setStartDate('')
          setEndDate('')
        }

        if (value === null && eadOption !== undefined && startDate) {
          onChangeFilter({
            value: attendanceSource ? [startDate, endDate] : null,
            type,
            name: name
              ? name
              : !filter?.courseProduct && eadOption === 'individual'
              ? 'Gestão financeira'
              : '',
            attendanceSource: attendanceSource ?? '',
            eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
            courseProduct: courseProductCode
              ? courseProductCode
              : !filter?.courseProduct && eadOption === 'individual'
              ? '303'
              : '',
          })
        }

        if (type === null) {
          onChangeFilter(null)
          setAttendanceSourceActive(undefined)
          setCourseProductActive(undefined)
        }
      }

      // highlight
      if (mainOption === 'highlight') {
        if (type) {
          if (value !== null && optionsSingleStringValue.includes(type) && value) {
            onChangeHighlight({
              type,
              value: Array.isArray(value) ? value.join(',') : value,
              name: name ?? '',
              attendanceSource: courseProductCode ? '5' : attendanceSource ? attendanceSource : '',
              eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
              courseProduct: courseProductCode ?? '',
            })

            return
          }

          if (optionsBooleanValue.includes(type)) {
            onChangeHighlight({
              type,
              value: type,
            })

            return
          }
        }

        if (value === null && eadOption === undefined) {
          onChangeHighlight({
            value: attendanceSource ? [startDateHighlight, endDateHighlight] : null,
            type,
            name: name ?? '',
            attendanceSource: attendanceSource ?? '',
            eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
            courseProduct: courseProductCode ?? '',
          })
          setAttendanceSourceHighlightActive(attendanceSource ? attendanceSource : '')
          setStartDateHighlight('')
          setEndDateHighlight('')
        }

        if (value === null && eadOption !== undefined && startDateHighlight) {
          onChangeHighlight({
            value: attendanceSource ? [startDateHighlight, endDateHighlight] : null,
            type,
            name: name
              ? name
              : !highlight?.courseProduct && eadOption === 'individual'
              ? 'Gestão financeira'
              : '',
            attendanceSource: attendanceSource ?? '',
            eadOption: courseProductCode ? 'individual' : eadOption ? eadOption : '',
            courseProduct: courseProductCode
              ? courseProductCode
              : !highlight?.courseProduct && eadOption === 'individual'
              ? '303'
              : '',
          })
        }

        if (type === null) {
          onChangeHighlight(null)
          setAttendanceSourceHighlightActive(undefined)
          setCourseProductHighlightActive(undefined)
        }
      }
    },
    [onChangeFilter, onChangeHighlight, startDate, endDate],
  )

  const isHighlightCensus =
    highlight && census.includes(highlight?.type) && highlight?.value === 'censusUpdated'
  const isHighlightProfits =
    highlight && profits.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightScores =
    highlight && scores.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightIndicators = isHighlightProfits || isHighlightScores

  const censusMessage =
    highlight?.type === 'area'
      ? ' empresas por km²'
      : highlight?.type === 'perCapta'
      ? ' empresas per capita'
      : null

  const noCensusData =
    isHighlightCensus && (highlightSum === null || highlightSum === undefined || highlightSum === 0)
  const noDataHighlightMessage = isHighlightCensus
    ? 'Sem dados do IBGE para este nível'
    : isHighlightProfits || isHighlightScores
    ? 'Sem dados do Radar Ali e Brasil Mais para este nível'
    : ''

  const value =
    isHighlightCensus && countSum !== null && highlightSum !== null && countSum !== 0
      ? formatAsBrNumber(countSum / Number(highlightSum))
      : highlight &&
        !isNullOrUndefined(highlightSum) &&
        countSum !== null &&
        !isHighlightProfits &&
        !isHighlightScores
      ? `${countSum !== 0 ? formatAsPercentage(Number(highlightSum) / countSum) : 0}%`
      : highlight && !isNullOrUndefined(highlightSum) && (isHighlightProfits || isHighlightScores)
      ? formatAsBrNumber(Number(highlightSum))
      : countSum !== null && !isHighlightCensus && !isHighlightIndicators
      ? formatAsBrNumber(countSum, 0)
      : null

  const isLoadingMemo = useMemo(() => {
    const isLoadingDefault = isLoading && countSum === 0
    const isLoadingInfinity = isLoading === false && Number(countSum) === -Infinity

    return isLoadingDefault ? isLoadingDefault : isLoadingInfinity ? isLoadingInfinity : null
  }, [isLoading, countSum, loadingMessage, highlight, highlightSum])

  const result = useMemo(() => {
    const onlyTheseLevels =
      level &&
      (level.geo === 'city' ||
        level.geo === 'neighborhood' ||
        level.geo === 'street' ||
        level.geo === 'clusteredLegalEntity' ||
        level.geo === 'legalEntity')

    const noDataHighlight =
      highlightSum === null &&
      onlyTheseLevels &&
      (isHighlightCensus || isHighlightProfits || isHighlightScores)
        ? noDataHighlightMessage
        : null
    const minValueHighlight = value === '' && highlight !== null ? '0%' : null
    const emptyValueNullableHighlight =
      value === '' && highlight === null && highlightSum ? Number(value) : null
    const isProfitsValue =
      isHighlightProfits && noDataHighlight === null && value !== null ? `R$ ${value}` : null
    const isScoresValue = isHighlightScores && noDataHighlight === null ? `Média: ${value}` : null

    return isLoading === false
      ? noDataHighlight
        ? noDataHighlight
        : minValueHighlight
        ? minValueHighlight
        : emptyValueNullableHighlight
        ? emptyValueNullableHighlight
        : isProfitsValue
        ? isProfitsValue
        : isScoresValue
        ? isScoresValue
        : value
      : ''
  }, [
    level,
    isHighlightCensusEnabled,
    isHighlightCensus,
    noCensusData,
    isHighlightProfitsEnabled,
    isHighlightIndicators,
    value,
    highlight,
    isHighlightProfits,
    isHighlightScores,
    highlightSum,
    noDataHighlightMessage,
    isLoading,
  ])

  const resultSuffix = useMemo(() => {
    const highlightDefaultSuffix =
      highlight &&
      highlightSum !== null &&
      highlight.type !== 'perCapta' &&
      highlight.type !== 'area' &&
      !isHighlightProfits &&
      !isHighlightScores
        ? ' das empresas'
        : null
    const highlightScoresSuffix =
      isHighlightScores && highlightSum !== null && value ? ' de 5' : null
    const highlightCensusSuffix =
      highlight !== null && isHighlightCensus ? ` ${censusMessage}` : null
    const legalEntitySuffix =
      !highlight || (highlight && highlight.value === null)
        ? Number(value) === 1
          ? ' empresa'
          : ' empresas'
        : null

    return (
      highlightDefaultSuffix || highlightScoresSuffix || highlightCensusSuffix || legalEntitySuffix
    )
  }, [
    highlight,
    highlightSum,
    isHighlightProfits,
    isHighlightScores,
    censusMessage,
    noCensusData,
    value,
  ])

  const hideAdditionalInformationCondition = useMemo(() => {
    const highlightPerCapta =
      highlight &&
      highlight.type === 'perCapta' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightArea =
      highlight &&
      highlight.type === 'area' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightValue =
      highlight && highlight.type !== 'perCapta' && highlight.type !== 'area' && highlight.value
        ? true
        : false
    const highlightSumNullable =
      highlight &&
      (highlight.type === 'perCapta' || highlight.type === 'area') &&
      highlight.value === 'censusUpdated' &&
      highlightSum === null &&
      level &&
      level.geo === 'city'
        ? true
        : false

    return highlightPerCapta || highlightArea || highlightValue || highlightSumNullable
  }, [highlight, highlightSum, isHighlightScores, isHighlightProfits, level, value])

  const calculateIndexValue = (highlightSum, countSum, additionalInformation) => {
    if (highlightSum === null || highlightSum === undefined) {
      return countSum !== null ? formatAsBrNumber(countSum, 0) : null
    }

    if (countSum === null || countSum === 0) {
      return '0%'
    }

    const [firstValue, secondValue] = additionalInformation
      ? additionalInformation.split(',').map(value => Number(value.trim().replace(/[\.,]/g, '')))
      : [0, 0]

    const adjustedHighlightSum = Number(highlightSum.toString().replace(/[\.,]/g, '')) + firstValue
    const adjustedCountSum = Number(countSum) + secondValue

    const percentage =
      highlight && filter
        ? adjustedHighlightSum / countSum
        : adjustedHighlightSum / adjustedCountSum

    if (percentage > 1) {
      return `${formatAsPercentage(percentage)}%`
    }

    if (percentage < 0.001) {
      return '< 0.001%'
    }

    return (percentage * 100).toFixed(2).replace('.', ',') + '%'
  }

  const indexValue = calculateIndexValue(highlightSum, countSum, additionalInformation)

  const entityData = useMemo(() => {
    if (isLoadingMemo || isLoading || additionalInformationIsLoading) {
      handleEntityData('Carregando...')
    }

    if ((!isMobile && result === null) || resultSuffix === null) {
      handleEntityData('Sem dados disponíveis')
    }

    if (!isLoading && !isLoadingMemo && result !== null && resultSuffix !== null) {
      const [firstValue, secondValue] = additionalInformation
        ? additionalInformation.split(',').map(value => Number(value.trim().replace(/[\.,]/g, '')))
        : [0, 0]

      const sanitizedHighlightSum = highlightSum
        ? Number(highlightSum.toString().replace(/[\.,]/g, ''))
        : 0

      const resultWithAdditionalInformationHighlight = sanitizedHighlightSum + firstValue

      const adjustedCountSum = countSum ? Number(countSum) + secondValue : 0

      const suffix =
        highlight && filter
          ? `de ${formatAsBrNumber(
              Number(countSum) + Number(firstValue),
              0,
            )} empresas (${indexValue})`
          : highlightSum && indexValue
          ? `de ${formatAsBrNumber(adjustedCountSum, 0)} empresas (${indexValue})`
          : ''

      const sanitizedResult = result ? Number(result.toString().replace(/[\.,]/g, '')) : 0
      const sanitizedAdditionalInformation = additionalInformation
        ? Number(additionalInformation.toString().replace(/[\.,]/g, ''))
        : 0

      const resultWithAdditionalInformation = sanitizedResult + sanitizedAdditionalInformation

      handleEntityData({
        selectedEntities:
          highlight && sanitizedHighlightSum
            ? resultWithAdditionalInformationHighlight
            : resultWithAdditionalInformation,
        availableEntities: highlight && sanitizedHighlightSum ? highlightSum : result,
        suffix: highlight && sanitizedHighlightSum ? suffix : resultSuffix ?? '',
        outOfRangeTotal: additionalInformation ?? undefined,
        definitionType: 'customized',
        legalEntityActiveCount: Number(countSum),
      })
      return {
        selectedEntities: highlight
          ? resultWithAdditionalInformationHighlight
          : resultWithAdditionalInformation,
        availableEntities: highlight ? highlightSum : result,
        suffix: highlight ? suffix : resultSuffix ?? '',
        outOfRangeTotal: additionalInformation ?? undefined,
        definitionType: 'customized',
        legalEntityActiveCount: Number(countSum),
      }
    }
  }, [
    result,
    resultSuffix,
    isMobile,
    isLoadingMemo,
    isLoading,
    highlightSum,
    countSum,
    additionalInformation,
    additionalInformationIsLoading,
  ])

  useAdditionalInformation(false, setAdditionalInformation, setAdditionalInformationIsLoading)

  useEffect(() => {
    if (additionalInformation) {
      handleOutOfRangeEntities(additionalInformation)
    }
  }, [additionalInformation])

  return (
    <>
      <Wrapper>
        {isMobile ? (
          <TopCard title='Mapa customizado de empresas ativas' />
        ) : (
          <div className='button-wrapper'>
            <Link href='/app/map'>
              <BackIcon width={10} height={10} />
              <span> Voltar </span>
            </Link>
          </div>
        )}
        <div className='containers'>
          <div>
            <SectionTitle>
              <span>Filtros</span>
            </SectionTitle>
            <fieldset className='section-container'>
              <MenuSection>Encontrar empresas</MenuSection>
              <div className='item-container'>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Filtrar</label>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                    <InfoIcon width={14} height={14} fill={colors.sebraeBlue}>
                      <title>Por quantitativo</title>
                    </InfoIcon>
                  </div>
                </div>
                <div style={{ marginTop: '4px' }}>
                  <EnumeratedField
                    fieldSchema={enumerated(filterOptions).isOptional()}
                    value={filter?.type}
                    onDirectChange={(value: any) =>
                      handleOption(null, 'filter', value, undefined, undefined)
                    }
                    meta={{
                      placeholder: 'Por porte, segmento, CNAE, etc...',
                      isDisabled: error ? true : false,
                    }}
                  />
                </div>
              </div>

              {filter?.type === 'size' ? (
                <div className='item-container'>
                  <label>Porte</label>
                  <div style={{ marginTop: '4px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(sizeOptions).isOptional()}
                      value={filter.value}
                      onDirectChange={(value: any) => handleOption(value, 'filter', 'size')}
                    />
                  </div>
                </div>
              ) : null}

              {filter?.type === 'attendance' && eachSourceAttendanceDatesFormatted ? (
                <div className='item-container'>
                  <div style={{ marginTop: '2px' }} />
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '4px' }}>
                    <AttendanceField
                      fieldSchema={enumerated(eachSourceAttendanceDatesFormatted).isOptional()}
                      meta={{
                        isDisabled: filter?.courseProduct ? true : false,
                      }}
                      value={filter?.courseProduct ? '5' : filter.attendanceSource}
                      onDirectChange={(value: any) =>
                        handleOption(null, 'filter', 'attendance', undefined, value)
                      }
                    />
                  </div>
                </div>
              ) : null}

              {filter?.type === 'attendance' &&
              eachSourceAttendanceDatesFormatted &&
              filter.attendanceSource === '5' ? (
                <div className='item-container'>
                  <label>Nível</label>
                  <div style={{ marginTop: '4px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(eadOptions).isOptional()}
                      value={filter.eadOption ? filter.eadOption : 'all'}
                      onDirectChange={(value: any) =>
                        handleOption(
                          null,
                          'filter',
                          'attendance',
                          undefined,
                          attendanceSourceActive,
                          value,
                          undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {filter?.type === 'attendance' &&
              eachSourceAttendanceDatesFormatted &&
              filter.attendanceSource === '5' &&
              (attendanceEadOptionActive === 'individual' ||
                (filter?.eadOption === 'individual' && filter.name !== undefined)) ? (
                <div className='item-container'>
                  <div style={{ marginTop: '2px' }}></div>
                  <label>Curso</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={courseProductQueryFactory}
                      idProperty='productCode'
                      labelProperty='productName'
                      preLoad
                      value={
                        { productCode: filter?.courseProduct, productName: filter?.name } || {
                          productCode: '303',
                          productName: 'Gestão financeira',
                        }
                      }
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value.id) : null,
                          'filter',
                          'attendance',
                          value?.productName ? String(value.productName) : undefined,
                          attendanceSourceActive,
                          'individual',
                          value?.productCode ? String(value.productCode) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {startDate &&
              ((filter?.type === 'attendance' &&
                eachSourceAttendanceDatesFormatted &&
                filter.attendanceSource !== '5' &&
                (attendanceSourceActive || filter.attendanceSource)) ||
                (filter?.type === 'attendance' &&
                  eachSourceAttendanceDatesFormatted &&
                  filter.attendanceSource === '5')) ? (
                <div className='item-container results-container'>
                  <div className='item-container'>
                    <DateField
                      id='start-date-field'
                      value={startDate}
                      label='Data inicial'
                      onDirectChange={date => {
                        setStartDate(format(date, 'yyyy-MM-dd'))
                      }}
                      placeholder='Início'
                      meta={{
                        maxDate: new Date(),
                        minDate: subYears(new Date(), 10),
                      }}
                      isRequired
                    />
                  </div>
                  <div className='item-container'>
                    <DateField
                      id={'end-date-field'}
                      value={endDate}
                      label='Data final'
                      onDirectChange={date => {
                        setEndDate(format(date, 'yyyy-MM-dd'))
                      }}
                      placeholder='Final'
                      meta={{
                        maxDate: new Date(),
                        minDate: subYears(new Date(), 10),
                      }}
                      isRequired
                    />
                  </div>
                </div>
              ) : null}

              {filter?.type === 'cnaes' ? (
                <div className='item-container'>
                  <label>CNAE</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={cnaeQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={filter.value ? { id: filter.value, name: filter.name } : undefined}
                      onDirectChange={value =>
                        handleOption(
                          String(value?.id),
                          'filter',
                          'cnaes',
                          value?.name ? String(value?.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
              {filter?.type === 'segment' ? (
                <div className='item-container'>
                  <label>Segmentos</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={segmentQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={filter.value ? { id: filter.value, name: filter.name } : undefined}
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value.id) : null,
                          'filter',
                          'segment',
                          value?.name ? String(value.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {filter?.type === 'legalNatureId' ? (
                <div className='item-container'>
                  <label>Natureza jurídica</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={legalNatureQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={filter?.value ? { id: filter.value, name: filter.name! } : undefined}
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value.id) : null,
                          'filter',
                          'legalNatureId',
                          value?.name ? String(value.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              <div>
                <LineHr />
              </div>

              <div className='item-container'>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Destacar visualização</label>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                    <InfoIcon width={14} height={14} fill={colors.sebraeBlue}>
                      <title>Por porcentagem ou outras métricas</title>
                    </InfoIcon>
                  </div>
                </div>
                <div style={{ marginTop: '4px' }}>
                  <EnumeratedField
                    fieldSchema={enumerated(highlightOptions).isOptional()}
                    value={highlight?.type}
                    onDirectChange={(value: any) => handleOption(null, 'highlight', value)}
                    meta={{
                      placeholder: 'Por quantidade de empresas',
                      isDisabled: isHeatmapEnabled || error ? true : false,
                    }}
                  />
                </div>
              </div>

              {highlight?.type === 'size' ? (
                <div className='item-container'>
                  <label>Porte</label>
                  <div style={{ marginTop: '4px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(sizeOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) => handleOption(value, 'highlight', 'size')}
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'attendance' && eachSourceAttendanceDatesFormatted ? (
                <div className='item-container'>
                  <div style={{ marginTop: '2px' }}></div>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '4px' }}>
                    <AttendanceField
                      fieldSchema={enumerated(eachSourceAttendanceDatesFormatted).isOptional()}
                      value={highlight?.courseProduct ? '5' : highlight.attendanceSource}
                      onDirectChange={(value: any) =>
                        handleOption(null, 'highlight', 'attendance', undefined, value)
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'attendance' &&
              eachSourceAttendanceDatesFormatted &&
              highlight.attendanceSource === '5' ? (
                <div className='item-container'>
                  <label>Nível</label>
                  <div style={{ marginTop: '4px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(eadOptions).isOptional()}
                      value={highlight.eadOption || 'all'}
                      onDirectChange={(value: any) =>
                        handleOption(
                          null,
                          'highlight',
                          'attendance',
                          undefined,
                          attendanceSourceHighlightActive,
                          value,
                          undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'attendance' &&
              eachSourceAttendanceDatesFormatted &&
              highlight.attendanceSource === '5' &&
              highlight.eadOption === 'individual' ? (
                <div className='item-container'>
                  <div style={{ marginTop: '2px' }}></div>
                  <label>Curso</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={courseProductQueryFactory}
                      idProperty='productCode'
                      labelProperty='productName'
                      value={
                        { productCode: highlight?.courseProduct, productName: highlight?.name } || {
                          productCode: '303',
                          productName: 'Gestão financeira',
                        }
                      }
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value.id) : null,
                          'highlight',
                          'attendance',
                          value?.productName ? String(value?.productName) : undefined,
                          attendanceSourceHighlightActive,
                          'individual',
                          value?.productCode ? String(value?.productCode) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {startDateHighlight &&
              ((highlight?.type === 'attendance' &&
                eachSourceAttendanceDatesFormatted &&
                highlight.attendanceSource !== '5' &&
                (attendanceSourceActive || highlight.attendanceSource)) ||
                (highlight?.type === 'attendance' &&
                  eachSourceAttendanceDatesFormatted &&
                  highlight.attendanceSource === '5')) ? (
                <div className='item-container results-container'>
                  <div className='item-container'>
                    <DateField
                      id='start-date-field'
                      value={startDateHighlight}
                      label='Data inicial'
                      onDirectChange={date => {
                        setStartDateHighlight(date ? format(date, 'yyyy-MM-dd') : startDate)
                      }}
                      placeholder='Início'
                      meta={{
                        maxDate: new Date(),
                        minDate: subYears(new Date(), 10),
                      }}
                      isRequired
                    />
                  </div>
                  <div className='item-container'>
                    <DateField
                      id={'end-date-field'}
                      value={endDateHighlight}
                      label='Data final'
                      onDirectChange={date => {
                        setEndDateHighlight(date ? format(date, 'yyyy-MM-dd') : endDate)
                      }}
                      placeholder='Final'
                      meta={{
                        maxDate: new Date(),
                        minDate: subYears(new Date(), 10),
                      }}
                      isRequired
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'cnaes' ? (
                <div className='item-container'>
                  <label>CNAE</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={cnaeQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={
                        highlight?.value
                          ? { id: highlight.value, name: highlight.name! }
                          : undefined
                      }
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value?.id) : null,
                          'highlight',
                          'cnaes',
                          value?.name ? String(value?.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'segment' ? (
                <div className='item-container'>
                  <label>Segmentos</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={segmentQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={
                        highlight?.value
                          ? { id: highlight.value, name: highlight.name! }
                          : undefined
                      }
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value?.id) : null,
                          'highlight',
                          'segment',
                          value?.name ? String(value?.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'legalNatureId' ? (
                <div className='item-container'>
                  <label>Natureza jurídica</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={legalNatureQueryFactory}
                      idProperty='id'
                      labelProperty='name'
                      preLoad
                      value={
                        highlight?.value
                          ? { id: highlight.value, name: highlight.name! }
                          : undefined
                      }
                      onDirectChange={value =>
                        handleOption(
                          value?.id ? String(value?.id) : null,
                          'highlight',
                          'legalNatureId',
                          value?.name ? String(value?.name) : undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'area' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(censusOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) => handleOption(value, 'highlight', 'area')}
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'perCapta' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(censusOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) => handleOption(value, 'highlight', 'perCapta')}
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'profits' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) => handleOption(value, 'highlight', 'profits')}
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'cost' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) => handleOption(value, 'highlight', 'cost')}
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreOperationsManagement' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreOperationsManagement')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreTransformation' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreTransformation')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreIndicatorsManagement' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreIndicatorsManagement')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreInnovation' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreInnovation')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreMarketing' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreMarketing')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {highlight?.type === 'scoreSustainablePractices' ? (
                <div className='item-container'>
                  <label>Fonte de dados</label>
                  <div style={{ marginTop: '5px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(profitsOptions).isOptional()}
                      value={highlight?.value}
                      onDirectChange={(value: any) =>
                        handleOption(value, 'highlight', 'scoreSustainablePractices')
                      }
                    />
                  </div>
                </div>
              ) : null}

              {!error ? (
                <div className='item-container results-container'>
                  {isMobile && entityData && entityData.selectedEntities !== null ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        paddingTop: '15px',
                        paddingBottom: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '25px',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <TopCardIcon>
                          <div className='tooltip-container'>
                            <InfoIcon width={16} height={16} fill={colors.sebraeBlue} />
                            <div className='tooltip-text'>
                              A seleção total inclui:
                              <ul>
                                <li>
                                  <strong>
                                    {entityData?.availableEntities?.toLocaleString('pt-BR')}
                                  </strong>{' '}
                                  empresas{' '}
                                  {entityData?.definitionType === 'customized' ? 'ativas' : null}{' '}
                                  disponíveis no mapa.
                                </li>
                                {Number(entityData?.outOfRangeTotal) > 0 ? (
                                  <li>
                                    <strong>{entityData?.outOfRangeTotal}</strong> empresas{' '}
                                    {entityData?.definitionType === 'customized' ? 'ativas' : null}{' '}
                                    indisponíveis no mapa por endereçamento limitado.
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </TopCardIcon>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          width: '50px',
                          flexDirection: 'column',
                        }}
                      >
                        <label>Total da seleção</label>
                        {entityData?.outOfRangeTotal === 'Carregando...' ||
                        entityData?.selectedEntities === undefined ||
                        Number.isNaN(Number(entityData?.selectedEntities)) ? (
                          'Carregando...'
                        ) : (
                          <span>
                            {`${entityData.selectedEntities?.toLocaleString('pt-BR')} ${
                              entityData?.suffix
                            }`}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {!isLoading && isMobile && (
                    <Button onClick={toggleMenuVisibility}>Ver Mapa</Button>
                  )}
                </div>
              ) : null}
            </fieldset>
          </div>
        </div>
        {(isHighlightScores || isHighlightProfits) && !isLoading && (
          <div className='alert-info' role='alert'>
            Informações baseadas em empresas que já foram atendidas pelo Brasil Mais ou Radar ALI.
            <AlertIcon fill={'#f1c40f'} width={18} height={18} />
          </div>
        )}
        {highlight &&
        highlight.type !== null &&
        highlight.value === null ? null : isHighlightCensusEnabled &&
          !error &&
          isHighlightProfitsEnabled ? (
          <LegendContainer
            isHeatmapEnabled={isHeatmapEnabled}
            maxValue={maxValue ? maxValue : null}
            minValue={minValue ? minValue : null}
            isLoadingLegend={isLoading}
            maxPercentColor={minPercentColor ? maxPercentColor : null}
            minPercentColor={minPercentColor ? minPercentColor : null}
            highlight={highlight}
            level={level?.geo}
          />
        ) : null}
      </Wrapper>
    </>
  )
}
