import {
  between,
  boolean,
  count,
  descending,
  distinctOn,
  entity,
  equals,
  likeAny,
  notNull,
  number,
  query,
  string,
  truthy,
} from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import { FilterHighlight, optionsBooleanValue } from '../map/legalEntityGeoprocessingMapLevels.data'
import { DefinedOptionMerged } from '../map/menu/legalEntityGeoprocessingMapMenu.data'
import { extractDates } from '../map/definedQueries/utils'
import { format, subYears } from 'date-fns'

interface mapHeatmapQueryOptions {
  filter: FilterHighlight | null
  availableGeoLevel: string
  mapLevel: string
  parentId: string | null
  definedOption?: DefinedOptionMerged | null
}

export default function mapHeatmapQuery({
  filter,
  availableGeoLevel,
  mapLevel,
  parentId,
  definedOption,
}: mapHeatmapQueryOptions) {
  const hasFilter = filter !== null && filter.value !== null
  const [filterStartDate, filterFinalDate] =
    filter?.value && typeof filter.value === 'string'
      ? extractDates(filter.value)
      : [format(subYears(new Date(), 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]

  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  const generatedQuery = query('legalEntityGeoprocessing')
    .select({
      location: distinctOn(
        asGeoJson(
          entity('legalEntityGeoprocessing').property(
            `${availableGeoLevel}LevelHeatmapCoordinates`,
          ),
        ),
      ),
      aggregator: entity('legalEntityGeoprocessing').property(
        `${availableGeoLevel}LevelHeatmapCoordinates`,
      ),
      weight: count(entity('legalEntityGeoprocessing').property('legalEntityId')),
    })
    .where(
      ...[
        notNull(
          entity('legalEntityGeoprocessing').property(
            `${availableGeoLevel}LevelHeatmapCoordinates`,
          ),
        ),
        parentId
          ? equals(
              entity('legalEntityGeoprocessing').property(`${mapLevel}Id`),
              string().value(parentId),
            )
          : null,
        filter?.type === 'size' && filter?.value
          ? equals(
              entity('legalEntity').property(`${filter?.type}`),
              string().value(`${filter?.value!}`),
            )
          : null,
        filter?.type === 'cnae' && filter?.value
          ? likeAny(
              entity('legalEntity').property('cnaes'),
              filter?.value
                .toString()
                .split(',')
                .map(cnae_id => string().value(`%,${cnae_id!}%`)),
              { sensitive: true },
            )
          : null,
        filter?.type === 'legalNature' && filter?.value
          ? equals(
              entity('legalEntity').property('legalNatureId'),
              string().value(`${filter?.value!}`),
            )
          : null,

        (filter?.type === 'attendance' && filter?.value && filter?.attendanceSource) ||
        (definedOption?.type === 'legalEntityAttendance' &&
          definedOption?.source &&
          definedOption?.default !== 'legalEntityAttendancePortfolioEadQuery' &&
          definedOption?.genericType !== 'attendanceIndex')
          ? equals(
              entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
              number().value(
                Number(filter?.attendanceSource ? filter?.attendanceSource : definedOption?.source),
              ),
            )
          : null,

        (filter?.type === 'attendance' && filter.eadOption === 'portfolio') ||
        definedOption?.default === 'legalEntityAttendancePortfolioEadQuery'
          ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
          : null,

        optionsBooleanValue.includes(`${filter?.type}`)
          ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
          : null,

        filterStartDate &&
        filterFinalDate &&
        filter &&
        filter.type === 'attendance' &&
        filter.attendanceSource &&
        filter.attendanceSource !== '5'
          ? between(
              entity('legalEntityAttendance').property('startDate'),
              string().value(filterStartDate),
              string().value(filterFinalDate),
            )
          : null,

        filterStartDate && filterFinalDate && filter?.type === 'attendance' && filter?.courseProduct
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              string().value(filterStartDate),
              string().value(filterFinalDate),
            )
          : null,

        // Dashboard
        definedOption &&
        definedOption.type === 'legalEntityAttendance' &&
        definedOption.searchType === 'dateRange'
          ? between(
              definedOption.source === 5 || definedOption.source === 1
                ? entity('courseRegisterIndividual').property('registerDate')
                : entity('legalEntityAttendance').property('startDate'),
              string().value(firstDayOfCurrentYearFormatted),
              entity('dataSource').property('endDate'),
            )
          : null,
      ].filter(truthy),
    )
    .order(descending('weight'))
    .limit(15000)

  if (hasFilter || definedOption) {
    return generatedQuery
      .join({
        current: entity('legalEntityGeoprocessing').property('legalEntityId'),
        target: entity('legalEntity').property('id'),
        join: 'inner',
      })
      [
        (filter?.type === 'attendance' && filter?.value && filter?.attendanceSource) ||
        (definedOption?.type === 'legalEntityAttendance' &&
          definedOption?.source &&
          definedOption?.genericType !== 'attendanceIndex')
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntity').property('id'),
        target: entity('legalEntityAttendance').property('legalEntityId'),
        join: 'inner',
      })

      [
        (filter?.type === 'attendance' && filter?.value && filter?.attendanceSource) ||
        (definedOption?.type === 'legalEntityAttendance' &&
          definedOption?.source &&
          definedOption?.genericType !== 'attendanceIndex')
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityAttendance').property('id'),
        target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
        join: 'inner',
      })

      [
        (filter?.type === 'attendance' && filter?.value && filter?.attendanceSource) ||
        (definedOption?.type === 'legalEntityAttendance' &&
          definedOption?.source &&
          definedOption?.genericType !== 'attendanceIndex')
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
        target: entity('dataSource').property('id'),
        join: 'inner',
      })

      [
        // EaD
        (filterStartDate &&
          filterFinalDate &&
          filter?.type === 'attendance' &&
          filter?.courseProduct) ||
        (filter?.type === 'attendance' && filter.eadOption === 'portfolio') ||
        definedOption?.source === 5 ||
        definedOption?.source === 1
          ? 'join'
          : 'dummy'
      ]({
        current: entity('legalEntityGeoprocessing').property('legalEntityId'),
        target: entity(`courseRegisterIndividual`).property('legalEntityId'),
        join: 'left',
      })
      [
        (filterStartDate &&
          filterFinalDate &&
          filter?.type === 'attendance' &&
          filter?.courseProduct) ||
        (filter?.type === 'attendance' && filter.eadOption === 'portfolio') ||
        definedOption?.default === 'legalEntityAttendancePortfolioEadQuery'
          ? 'join'
          : 'dummy'
      ]({
        current: entity('courseRegisterIndividual').property('courseProductId'),
        target: entity(`courseProduct`).property('id'),
        join: 'left',
      })
  }
  return generatedQuery
}
