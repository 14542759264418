import { Aggregation, AggregationBase } from './aggregation'
import { EntityKey, EntityReference, EntitySchema, FindEntity } from './entity'
import { ValueFunction } from './functions/function'
import { every, LogicalOperator, Operator } from './operator'
import { Order } from './order'
import { InferPropertyNative, Property, PropertyBase } from './property'
import { NativeBaseOptional, TypeWithValue } from './types/type'

export type Selectable =
  | Property<EntityKey, string, string, NativeBaseOptional>
  | AggregationBase
  | TypeWithValue
  | ValueFunction<NativeBaseOptional>
  | Operator

export type Selectables = {
  [key: string]: Selectable
}
export type Join = {
  current: Property<string, string, string, string | number | null>
  target: Property<string, string, string, string | number | null>
  join?: 'inner' | 'left'
}

export interface SerializableQuery<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection,
> {
  readonly callStack?: string[]
  readonly kind: 'query'
  readonly mode: 'read'
  readonly entityKey: MainEntityKey
  readonly entitySchema?: string
  readonly entityAlias: MainEntityKeyAlias
  readonly currentSelect: Selection
  readonly currentWhere?: LogicalOperator
  readonly currentOrder?: Array<Order<string>>
  readonly currentJoins: Array<Join>
  readonly currentOffset: number
  readonly currentLimit: number | 'all'
  readonly currentUnions?: SerializableQuery<string, string, Selection>[]
}

export type QueryBase = SerializableQuery<EntityKey, string, Selectables>

export interface Query<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection,
> extends SerializableQuery<MainEntityKey, MainEntityKeyAlias, Selection> {
  callStack?: string[]
  where: (...everyOperator: Operator[]) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  select: <NewCurrentSelectProperties>(
    properties: NewCurrentSelectProperties,
  ) => Query<MainEntityKey, MainEntityKeyAlias, NewCurrentSelectProperties>
  join: (join: Join) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  order: (...newOrder: Order<string>[]) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  dummy: (...any: any[]) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  offset: (newOffset: number) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  limit: (newLimit: number | 'all') => Query<MainEntityKey, MainEntityKeyAlias, Selection>
  union: (
    ...unions: Array<SerializableQuery<EntityKey, string, any>>
  ) => Query<MainEntityKey, MainEntityKeyAlias, Selection>
}

interface QueryOptions<EntitySchema extends string> {
  schema?: EntitySchema
}

export function getQueryCallStack() {
  try {
    try {
      throw new Error()
    } catch (err) {
      const callStack = [
        ...new Set(String((err as Error).stack).match(/(\/packages\/.+\.(tsx|ts))/gi)),
      ]
      return callStack
    }
  } catch (error) {
    console.error('Could not fetch query call stack', (error as Error).message)
  }
}

/**
 * Função usada na criação de queries feitas pela prixApi.
 * @param entityKeyOrAlias Nome ou alias da tabela, em camelCase.
 * @param options Parâmetros opcionais. Permite indicar qual o schema da tabela.
 * @returns Objeto query compatível com a prixApi para requisições em banco de dados.
 */
export default function query<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables,
>(
  entityKeyOrAlias:
    | MainEntityKey
    | EntityReference<MainEntityKey, MainEntityKeyAlias, FindEntity<MainEntityKey>>,
  options?: QueryOptions<EntitySchema>,
): Query<MainEntityKey, MainEntityKeyAlias, Selection> {
  let entitySchema: string | undefined = undefined
  if (options && options.schema) {
    entitySchema = options.schema
  }
  const stack = getQueryCallStack()
  return {
    callStack: stack,
    kind: 'query',
    mode: 'read',
    entityKey: typeof entityKeyOrAlias === 'string' ? entityKeyOrAlias : entityKeyOrAlias.key,
    entitySchema,
    entityAlias: (typeof entityKeyOrAlias === 'string'
      ? entityKeyOrAlias
      : entityKeyOrAlias.alias) as MainEntityKeyAlias,
    currentSelect: [] as unknown as Selection,
    currentJoins: [],
    currentOffset: 0,
    currentLimit: 35000,
    select<NewCurrentSelectProperties>(properties: NewCurrentSelectProperties) {
      return {
        ...this,
        currentSelect: properties,
      } as unknown as Query<MainEntityKey, MainEntityKeyAlias, NewCurrentSelectProperties>
    },
    where(...everyOperator) {
      return {
        ...this,
        currentWhere: every(...everyOperator),
      }
    },
    join(join) {
      return {
        ...this,
        currentJoins: [...this.currentJoins, join],
      }
    },
    order(...newOrder) {
      return {
        ...this,
        currentOrder: newOrder,
      }
    },
    offset(newOffset) {
      return {
        ...this,
        currentOffset: newOffset,
      }
    },
    limit(newLimit) {
      return {
        ...this,
        currentLimit: newLimit,
      }
    },
    union(...unions) {
      return {
        ...this,
        currentUnions: unions,
      }
    },
    dummy() {
      return this
    },
  }
}

export type InferSelection<CurrentQuery> = CurrentQuery extends SerializableQuery<
  string,
  string,
  infer Selection
>
  ? Selection
  : never

export type InferSelectableNative<CurrentSelectable> = CurrentSelectable extends PropertyBase
  ? InferPropertyNative<CurrentSelectable>
  : CurrentSelectable extends Aggregation<infer AggregationType, any>
  ? AggregationType
  : CurrentSelectable extends TypeWithValue<infer Native>
  ? Native
  : never

export type SelectablesToItem<CurrentSelectables> = CurrentSelectables extends Selectables
  ? {
      [NewKey in keyof CurrentSelectables]: InferSelectableNative<CurrentSelectables[NewKey]>
    }
  : never

export type InferQueryItem<CurrentQuery extends SerializableQuery<EntityKey, string, Selectables>> =
  SelectablesToItem<CurrentQuery['currentSelect']>
