import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import ErrorIcon from '~/meta/error.svg'
import { AppError, fromUnknownError } from '../../error'
import Loader from './loader'
import { useKeycloak } from '~/components/keycloak'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .svg {
    display: flex;
  }
  .error-message {
    margin-bottom: 5px;
    margin-top: 20px;
    align-items: center;
    max-width: 1000px;
    small {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.primary};
    }
    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 28px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const Button = styled.button`
  display: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 4px;
  align-self: center;
  padding: 0.5rem 2rem;
  margin: 0.625rem;
  transition-duration: 0.4s;
  border: 1px solid transparent;
  cursor: pointer;
  span {
    font-size: 16px;
    color: #fff;
  }
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid #fff;
  }
`

export interface HandleProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean
  error: Error | AppError | null | undefined
  onTryAgain?: () => void
  tryAgainButtonLabel?: string
  loginAction?: boolean
  children?: React.ReactNode
  stateRoles?: boolean
}

export default function Handle({
  isLoading = false,
  error,
  children = null,
  onTryAgain,
  tryAgainButtonLabel = 'Tentar novamente',
  loginAction,
  stateRoles,
  ...props
}: HandleProps) {
  const { logout } = useKeycloak()

  const finalError = useMemo(() => {
    if (!error) {
      return null
    }
    return fromUnknownError(error)
  }, [error])

  const handleTryAgainClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (!onTryAgain) {
        return
      }
      onTryAgain()
    },
    [onTryAgain],
  )

  const handleLogout = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault()
      window.location.href = '/'
    },
    [logout],
  )

  if (isLoading) {
    return <Wrapper {...props}>Carregando...</Wrapper>
  }
  if (!finalError) {
    return children as React.ReactElement
  }

  return (
    <Wrapper {...props}>
      <div className='svg'>
        <ErrorIcon width={400} height={400} />
      </div>
      <div className='error-message'>
        {error?.message !== 'Não autenticado' ? (
          <>
            <small>Ocorreu um erro</small>
            <br />
          </>
        ) : null}
        <span>{stateRoles ? error?.message : finalError.message}</span>
      </div>
      {onTryAgain && !stateRoles ? (
        <Button onClick={handleTryAgainClick}>{tryAgainButtonLabel}</Button>
      ) : null}
      {stateRoles ? <Button onClick={handleLogout}>Voltar ao login</Button> : null}
      {isLoading ? <Loader /> : null}
    </Wrapper>
  )
}
