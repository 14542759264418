import { SerializableQuery, truthy } from '~/prix'
import legalEntitiesGeoprocessingQuery from '../levels/legalEntitiesGeoprocessing.query'
import legalEntityAttendanceRadarAliQuery from '../definedQueries/legalEntityAttendanceRadarAli.query'
import legalEntityAttendanceRadarAliIndexQuery from '../definedQueries/legalEntityAttendanceRadarAliIndex.query'
import legalEntityAttendanceAllCoursesEadQuery from '../definedQueries/legalEntityAttendanceAllCoursesEad.query'
import legalEntityAttendanceAppSebraeQuery from '../definedQueries/legalEntityAttendanceAppSebrae.query'
import legalEntityAttendanceAppSebraeIndexQuery from '../definedQueries/legalEntityAttendanceAppSebraeIndex.query'
import legalEntityAttendancePortfolioEadQuery from '../definedQueries/legalEntityAttendancePortfolioEad.query'
import legalEntityAttendanceAllCoursesEadIndexQuery from '../definedQueries/legalEntityAttendanceAllCoursesEadIndex.query'
import legalEntityAttendancePortfolioEadIndexQuery from '../definedQueries/legalEntityAttendancePortfolioEadIndex.query'
import legalEntityAttendanceCourseDropoutQuery from '../definedQueries/legalEntityAttendanceCourseDropout.query'

/**
 * @param default auxiliar para o merge com as informações providas da URL e identificação do customized (utilizar nome padrão e único)
 * @param type auxiliar para diferenciar formatos por exemplo index que altera scripts para alterar valores do menu e colorização
 * @param searchType auxiliar para definir o tipo de busca (cnae | legalNature | lastMonthAndYearPeriod)
 * @param genericType auxiliar utilizado até então para queries
 * @param source auxiliar de fonte para o título do menu
 * @param menu definir em qual menu o item aparecerá
 * @param positionSubmenu definir a posição do item no submenu
 * @param filterName nome do filtro
 * @param filterType tipo do filtro
 * @param filterValue valor do filtro
 * @param filterAttendanceSource fonte do filtro
 * @param filterEadOption opção EaD do filtro
 * @param filterCourseProduct curso do filtro
 * @param highlightType tipo de destaque
 * @param highlightName nome do destaque
 * @param highlightValue valor do destaque
 * @param highlightAttendanceSource fonte do destaque
 * @param highlightEadOption opção EaD do destaque
 * @param highlightCourseProduct curso do destaque
 */

export interface DefinedOption {
  title: string
  subtitle?: string
  default: string
  type?: string
  genericType?: string
  query: () => SerializableQuery<string, string, {}>
  searchType?: string
  source?: number
  positionSubmenu?: number
  menu?: string
}

export interface DefinedOptionMerged extends DefinedOption {
  filterName?: string
  filterType?: string
  filterValue?: string | string[]
  filterAttendanceSource?: string
  filterEadOption?: string
  filterCourseProduct?: string
  highlightType?: string
  highlightName?: string
  highlightValue?: string
  highlightAttendanceSource?: string
  highlightEadOption?: string
  highlightCourseProduct?: string
}

export const sourceAux = [
  'Sem fonte',
  'Receita Federal Brasileira',
  'SEBRAE Na Sua Empresa',
  'Radar ALI',
  'Brasil Mais',
  'Todos os cursos (EaD)',
  'Cursos do portfólio (EaD)',
  'SEBRAE Na Sua Empresa (App)',
]

export const allLegalEntityGeoProcessingMapMenuItems = () =>
  [
    {
      title: 'Mapa customizado de empresas ativas',
      default: 'customized',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
    },
    // {
    //   title: 'Porcentagem de MEIs',
    //   default: 'legalEntityMeiPercentage',
    //   type: 'legalEntity',
    //   query: legalEntitiesGeoprocessingQuery,
    //   highlightType: 'isMei',
    //   highlightValue: 'isMei',
    // },
    {
      title: 'Quantidade de empresas por km²',
      default: 'legalEntityByKm2',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'area',
      highlightValue: 'censusUpdated',
    },
    {
      title: 'Quantidade de empresas per capita',
      default: 'legalEntityPerCapita',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'perCapta',
      highlightValue: 'censusUpdated',
    },
    // {
    //   title: 'Evasão de cursos',
    //   default: 'legalEntityAttendanceCourseDropout',
    //   type: 'legalEntity',
    //   query: legalEntityAttendanceCourseDropoutQuery,
    //   highlightType: 'courseDropout',
    //   highlightAttendanceSource: '5',
    //   highlightEadOption: 'all',
    // },

    //Índices de cobertura
    {
      title: 'SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAppSebraeIndexQuery,
      searchType: 'dateRange',
      source: 6,
      positionSubmenu: 7,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Radar ALI',
      default: 'legalEntityAttendanceRadarAliIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceRadarAliIndexQuery,
      searchType: 'dateRange',
      source: 3,
      positionSubmenu: 3,
      menu: 'Índice de cobertura',
    },
    {
      title: 'EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAllCoursesEadIndexQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 5,
      menu: 'Índice de cobertura',
    },
    {
      title: 'EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendancePortfolioEadIndexQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 6,
      menu: 'Índice de cobertura',
    },

    //Empresas atendidas
    {
      title: 'SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAppSebraeQuery,
      searchType: 'dateRange',
      source: 6,
      positionSubmenu: 7,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Radar ALI',
      default: 'legalEntityAttendanceRadarAli',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceRadarAliQuery,
      searchType: 'dateRange',
      source: 3,
      positionSubmenu: 3,
      menu: 'Empresas atendidas',
    },
    {
      title: 'EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAllCoursesEadQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 5,
      menu: 'Empresas atendidas',
    },
    {
      title: 'EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendancePortfolioEadQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 6,
      menu: 'Empresas atendidas',
    },
  ].filter(truthy)

export const customizedLegalEntityGeoProcessingMapMenuItems = {
  title: 'Busca customizada',
  default: 'customized',
  type: 'legalEntity',
  query: legalEntitiesGeoprocessingQuery,
}

export const legalEntityGeoprocessingMapMenuItems = () =>
  [
    // {
    //   title: 'Porcentagem de MEIs',
    //   default: 'legalEntityMeiPercentage',
    //   type: 'legalEntity',
    //   query: legalEntitiesGeoprocessingQuery,
    //   highlightType: 'isMei',
    //   highlightValue: 'isMei',
    // },
    {
      title: 'Quantidade de empresas por km²',
      default: 'legalEntityByKm2',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'area',
      highlightValue: 'censusUpdated',
    },
    {
      title: 'Quantidade de empresas per capita',
      default: 'legalEntityPerCapita',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'perCapta',
      highlightValue: 'censusUpdated',
    },
    // {
    //   title: 'Evasão de cursos',
    //   default: 'legalEntityAttendanceCourseDropout',
    //   type: 'legalEntity',
    //   query: legalEntityAttendanceCourseDropoutQuery,
    //   highlightType: 'courseDropout',
    //   highlightAttendanceSource: '5',
    //   highlightEadOption: 'all',
    // },

    //Índices de cobertura
    {
      title: 'SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAppSebraeIndexQuery,
      searchType: 'dateRange',
      source: 6,
      positionSubmenu: 7,
      menu: 'Índice de cobertura',
    },
    {
      title: 'EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendancePortfolioEadIndexQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 6,
      menu: 'Índice de cobertura',
    },
    {
      title: 'EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAllCoursesEadIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 5,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Radar ALI',
      default: 'legalEntityAttendanceRadarAliIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceRadarAliIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 3,
      positionSubmenu: 3,
      menu: 'Índice de cobertura',
    },

    {
      title: 'SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAppSebraeQuery,
      searchType: 'dateRange',
      source: 6,
      positionSubmenu: 7,
      menu: 'Empresas atendidas',
    },
    {
      title: 'EAD (Cursos do Portfólio)',
      default: 'legalEntityAttendancePortfolioEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendancePortfolioEadQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 6,
      menu: 'Empresas atendidas',
    },
    {
      title: 'EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAllCoursesEadQuery,
      searchType: 'dateRange',
      source: 5,
      positionSubmenu: 5,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Radar ALI',
      default: 'legalEntityAttendanceRadarAli',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceRadarAliQuery,
      searchType: 'dateRange',
      source: 3,
      positionSubmenu: 3,
      menu: 'Empresas atendidas',
    },
  ].filter(truthy)
