import React, { useMemo } from 'react'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import { Item, Items } from './dashboardGridItemAttendance.component'
import courseRegisterIndividualQuery from '../queries/courseRegisterIndividual.query'
import useItems from '~/prix/react/hooks/items'
import additionalInformationQuery from '~/packages/legalEntityGeoprocessing/map/additionalInformation/additionalInformation.query'
import { format } from 'date-fns'
import { parseDateToUTC } from '~/prix/types/date'

export default function DashboardGridItemCourseRegisterIndividual({
  userGeoStateAbbreviation,
  dataSourceId,
  isLastYearQuery,
  dataSourceEndDate,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isLastYearQuery?: boolean
  dataSourceEndDate?: any
}) {
  const totalAttendance = useItem(
    () =>
      courseRegisterIndividualQuery({
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId,
        isLastYearQuery,
      }),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  const currentDate = parseDateToUTC(new Date())

  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  const filter = useMemo(() => {
    if (dataSourceId && dataSourceEndDate && firstDayOfCurrentYearFormatted) {
      const dataSourceEndDateObject = new Date(dataSourceEndDate)

      const dataSourceEndDateFormatted = dataSourceEndDateObject
        ? format(dataSourceEndDateObject, 'yyyy-MM-dd')
        : null

      return {
        type: 'attendance',
        attendanceSource: dataSourceId,
        value: `${firstDayOfCurrentYearFormatted},${dataSourceEndDateFormatted}`,
      }
    }
    return null
  }, [firstDayOfCurrentYearFormatted, dataSourceId, dataSourceEndDate])

  const additionalInformationsResult = useItems(
    () =>
      dataSourceId && filter ? (additionalInformationQuery('country', '30', filter) as any) : null,
    [filter, dataSourceId],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: Boolean(dataSourceId && filter && dataSourceEndDate),
    },
  )

  const totalAttendanceCount = useMemo(() => {
    if (totalAttendance.item && additionalInformationsResult?.items) {
      const totalLegalEntity = totalAttendance.item?.totalLegalEntity

      const additionalInformationCount = additionalInformationsResult?.items[0]?.count

      if (totalLegalEntity == null && additionalInformationCount == null) {
        return null
      }

      if (additionalInformationCount == null) {
        return totalLegalEntity
      }

      if (totalLegalEntity == null) {
        return additionalInformationCount
      }

      return Number(totalLegalEntity) + Number(additionalInformationCount)
    }

    return null
  }, [totalAttendance.item?.totalLegalEntity, additionalInformationsResult?.items])

  return (
    <Handle
      isLoading={
        totalAttendance.isLoading ||
        additionalInformationsResult.isLoading ||
        totalAttendanceCount == null
      }
      error={totalAttendance.error}
      style={{ minHeight: '100px' }}
    >
      <Items>
        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.total))}
          </span>
          <span className='second'>Matrículas vinculadas a empresas realizadas</span>
        </Item>

        <Item>
          <span className='first'>{formatAsBrIntegerNumber(Number(totalAttendanceCount))}</span>
          <span className='second'>Empresas geocodificadas matriculadas</span>
        </Item>
      </Items>
    </Handle>
  )
}
