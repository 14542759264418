import { format } from 'date-fns'
import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  boolean,
  notNull,
  between,
  date,
  notEquals,
  ternary,
  every,
} from '~/prix'
import { parseDateToUTC } from '~/prix/types/date'

// Matriculas
export default function courseRegisterIndividualQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
  isLastYearQuery,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId?: number
  isLastYearQuery?: boolean
}) {
  const currentDate = parseDateToUTC(new Date())
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return query('legalEntity')
    .select({
      total: count(distinct(entity('courseRegisterIndividual').property('id'))),
      totalLegalEntity: count(
        distinct(
          ternary(
            every(
              notEquals(entity('legalEntity').property('state'), string().value('EX')),
              notNull(entity('legalEntityGeoprocessing').property('stateId')),
              notNull(entity('legalEntityGeoprocessing').property('cityId')),
            ),
            entity('legalEntity').property('id'),
            number().value(null as any),
          ),
        ),
      ),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    [dataSourceId ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    [dataSourceId ? 'join' : 'dummy']({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'left',
    })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('legalEntityAttendance').property('stateId'), string().value(stateId))
          : null,

        dataSourceId && dataSourceId === 1
          ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
          : null,

        dataSourceId && (dataSourceId === 5 || dataSourceId === 1)
          ? equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              number().value(5),
            )
          : null,

        isLastYearQuery === true
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              date().value(`${firstDayOfCurrentYearFormatted}`),
              entity('dataSource').property('endDate'),
            )
          : null,
      ].filter(truthy),
    )
    .limit(1)
}
