import { useEffect, useMemo, useState } from 'react'
import Supercluster from 'supercluster'
import { contrastGradientColor } from '~/design'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoItem,
  GeoItemGeom,
  geoLevelToGeoprocessingColumn,
  geoQueries,
  optionsBooleanValue,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import {
  between,
  boolean,
  convert,
  count,
  date,
  distinctOn,
  entity,
  equals,
  every,
  formatAsBrNumber,
  formatAsPercentage,
  isNull,
  likeAny,
  number,
  query,
  string,
  truthy,
} from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useItems from '~/prix/react/hooks/items'
import {
  GeoJsonLineString,
  GeoJsonMultiPolygon,
  GeoJsonPoint,
  GeoJsonMultiLineString,
} from '~/prix/types/geoJson'
import { DefinedOption } from './menu/legalEntityGeoprocessingMapMenu.data'
import { extractDates } from './definedQueries/utils'
import { format, subYears } from 'date-fns'

export default function useLegalEntityGeoprocessingStreetWithClustersMap({
  childrenGeoLevel,
  id,
  by,
  filter,
  highlight,
  isEnabled,
  definedOption,
}: {
  childrenGeoLevel: 'streetWithClusters'
  by: 'streetId'
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  isEnabled: boolean
  definedOption: DefinedOption | null
}) {
  const { google } = useGoogleMaps()
  const [items, setItems] = useState<Array<GeoItem> | null>(null)
  const [lastStreets, setLastStreets] = useState<Array<GeoItemGeom> | null>(null)
  const [streets, setStreets] = useState<Array<GeoItemGeom> | null>(null)

  const streetsResult = useItems(
    () => geoQueries.streets('streetId', id!),
    [childrenGeoLevel, id, by],
    { cache: 60 * 60 * 12, autoLoad: isEnabled },
  )

  useEffect(() => {
    if (
      (isEnabled && lastStreets === null && streetsResult.items !== null) ||
      (lastStreets !== null && streetsResult.items !== lastStreets)
    ) {
      setStreets(streetsResult.items ?? null)
      setLastStreets(streetsResult.items ?? null)
    }

    if (isEnabled === false) {
      setStreets(null)
    }
  }, [lastStreets, streetsResult, isEnabled])

  const isHighlightMode =
    (highlight?.type &&
      highlight?.value !== undefined &&
      highlight?.value !== null &&
      highlight?.value !== '') ||
    definedOption?.type === 'legalEntityAttendanceIndex'

  const definedQuery: any = definedOption ? definedOption.query : null

  const legalEntitiesGeoprocessingGroupedByStreet = useItems(
    () =>
      isEnabled && definedQuery
        ? definedQuery({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            idColumn: by,
            id,
            filter,
            highlight,
          })
        : (null as never),
    [childrenGeoLevel, by, id, filter?.value, highlight?.value, isEnabled, definedQuery],
    {
      cache: 60 * 60 * 24,
      autoLoad: isEnabled && definedQuery !== null,
    },
  )

  const [filterStartDate, filterFinalDate] =
    filter?.value && typeof filter.value === 'string'
      ? extractDates(filter.value)
      : [format(subYears(new Date(), 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
  const [highlightStartDate, highlightFinalDate] =
    highlight?.value && typeof highlight.value === 'string'
      ? extractDates(highlight.value)
      : [format(subYears(new Date(), 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]

  const legalEntitiesGeoprocessingByStreet = useItems(
    () =>
      query('legalEntityGeoprocessing')
        .select({
          legalEntityId: entity('legalEntityGeoprocessing').property('legalEntityId'),
          legalEntityCnpj: entity('legalEntity').property('cnpj'),
          tradeName: entity('legalEntity').property('tradeName'),
          corporateName: entity('legalEntity').property('corporateName'),
          pointOnStreetAgg: entity('legalEntityGeoprocessing').property('pointOnStreet'),
          pointOnStreet: distinctOn(
            asGeoJson(entity('legalEntityGeoprocessing').property('pointOnStreet')),
          ),
          pointOnStreetDirectionAngle: entity('legalEntityGeoprocessing').property(
            'pointOnStreetDirectionAngle',
          ),
          highlight:
            (highlight?.type === 'attendance' &&
              highlight?.value &&
              !highlight?.courseProduct &&
              highlight.eadOption !== 'portfolio') ||
            (definedOption?.type === 'legalEntityAttendanceIndex' &&
              definedOption.default !== 'legalEntityAttendancePortfolioEadIndexQuery')
              ? count(
                  equals(
                    entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                    number().value(
                      Number(
                        highlight?.attendanceSource
                          ? highlight?.attendanceSource
                          : definedOption?.source,
                      ),
                    ),
                  ),
                )
              : (highlight?.type === 'attendance' &&
                  highlight?.value &&
                  highlight.eadOption === 'portfolio' &&
                  !highlight?.courseProduct &&
                  highlightStartDate &&
                  highlightFinalDate) ||
                (definedOption?.default === 'legalEntityAttendancePortfolioEadIndexQuery' &&
                  highlightStartDate &&
                  highlightFinalDate)
              ? count(
                  every(
                    between(
                      entity('courseRegisterIndividual').property('registerDate'),
                      string().value(highlightStartDate),
                      string().value(highlightFinalDate),
                    ),
                    equals(
                      entity('courseProduct').property(`isActivePortfolio`),
                      boolean().value(true),
                      entity('legalEntityGeoprocessing').property('legalEntityId'),
                    ),
                  ),
                )
              : highlight?.type === 'attendance' &&
                highlight?.courseProduct &&
                highlight?.value &&
                highlightStartDate &&
                highlightFinalDate
              ? count(
                  every(
                    between(
                      entity('courseRegisterIndividual').property('registerDate'),
                      string().value(highlightStartDate),
                      string().value(highlightFinalDate),
                    ),
                    equals(
                      entity('courseProduct').property('productCode'),
                      string().value(highlight?.courseProduct),
                      entity('legalEntityGeoprocessing').property('legalEntityId'),
                    ),
                  ),
                )
              : highlight?.type === 'size' && highlight?.value
              ? equals(
                  entity('legalEntity').property('size'),
                  string().value(`${highlight.value!}`),
                )
              : highlight?.type === 'cnaes' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property(highlight?.type),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : highlight?.type === 'legalNatureId' && highlight?.value
              ? equals(
                  entity('legalEntity').property(highlight?.type),
                  string().value(`${highlight.value!}`),
                )
              : highlight?.type === 'segment' && highlight?.value
              ? count(
                  likeAny(
                    entity('legalEntity').property('cnaes'),
                    highlight?.value
                      .toString()
                      .split(',')
                      .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                    { sensitive: true },
                  ),
                )
              : optionsBooleanValue.includes(`${highlight?.type}`) && highlight?.value
              ? equals(entity('legalEntity').property(highlight?.type), boolean().value(true))
              : number().value(null as unknown as number),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        [
          // EaD source
          (filter?.type === 'attendance' && filter?.eadOption) ||
          (highlight?.type === 'attendance' && highlight?.eadOption)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity(`courseRegisterIndividual`).property('legalEntityId'),
          join: 'left',
        })
        [
          (filter?.type === 'attendance' && filter?.eadOption) ||
          (highlight?.type === 'attendance' && highlight?.eadOption)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity(`courseProduct`).property('id'),
          join: 'left',
        })
        [
          (filter?.type === 'attendance' && (filter?.attendanceSource || definedOption?.source)) ||
          (highlight?.type === 'attendance' && highlight?.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter?.type === 'attendance' && (filter?.attendanceSource || definedOption?.source)) ||
          (highlight?.type === 'attendance' && highlight?.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            // isNull(entity('legalEntity').property('deletedAt')),
            equals(entity('legalEntityGeoprocessing').property('streetId'), string().value(id!)),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            (filter?.type === 'attendance' && filter?.value && filter?.attendanceSource) ||
            (definedOption?.type === 'legalEntityAttendance' &&
              definedOption?.source &&
              definedOption?.default !== 'legalEntityAttendancePortfolioEadQuery' &&
              definedOption?.genericType !== 'attendanceIndex')
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(
                    Number(
                      filter?.attendanceSource ? filter?.attendanceSource : definedOption?.source,
                    ),
                  ),
                )
              : null,

            (filter?.type === 'attendance' &&
              filter?.value &&
              filter.eadOption === 'portfolio' &&
              !filter?.courseProduct) ||
            definedOption?.default === 'legalEntityAttendancePortfolioEadQuery'
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'attendance' && filter?.courseProduct && filter.value
              ? equals(
                  entity('courseProduct').property('productCode'),
                  string().value(filter?.courseProduct),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property(filter?.type),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'segment' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNatureId' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            filterStartDate &&
            filterFinalDate &&
            filter &&
            filter.type === 'attendance' &&
            filter.attendanceSource &&
            filter.attendanceSource !== '5'
              ? between(
                  convert(entity('legalEntityAttendance').property('startDate'), date()),
                  string().value(filterStartDate),
                  string().value(filterFinalDate),
                )
              : null,

            filterStartDate &&
            filterFinalDate &&
            filter?.type === 'attendance' &&
            filter?.courseProduct
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  string().value(filterStartDate),
                  string().value(filterFinalDate),
                )
              : null,
          ].filter(truthy),
        ),
    [by, id, filter?.value, highlight?.value, definedOption],
    {
      cache: 60 * 60 * 24,
      autoLoad: isEnabled,
    },
  )

  const maxValue = useMemo(() => {
    if (legalEntitiesGeoprocessingGroupedByStreet.items && !isHighlightMode) {
      return Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.count)
          }
          return 0
        }),
      )
    }
    if (legalEntitiesGeoprocessingGroupedByStreet.items && isHighlightMode) {
      return Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.highlight) / item.count
          }
          return 0
        }),
      )
    }
    if (!legalEntitiesGeoprocessingGroupedByStreet.items) {
      return -Infinity
    }
  }, [legalEntitiesGeoprocessingGroupedByStreet.items])

  const minValue = useMemo(() => {
    if (legalEntitiesGeoprocessingGroupedByStreet.items && !isHighlightMode) {
      return Math.min(
        Infinity,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.count)
          }
          return Infinity
        }),
      )
    }
    if (legalEntitiesGeoprocessingGroupedByStreet.items && isHighlightMode) {
      return Math.min(
        Infinity,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number' && item.count > 0) {
            return Number(item.highlight) / item.count
          }
          return Infinity
        }),
      )
    }
    return Infinity
  }, [legalEntitiesGeoprocessingGroupedByStreet.items, isHighlightMode])

  const legalEntitiesGeoprocessingGroupedByStreetMap = useMemo(() => {
    const items = legalEntitiesGeoprocessingGroupedByStreet?.items
    if (!items) {
      return null
    }

    type Item = (typeof items)[0]
    return items.reduce((acc, next) => {
      acc.set(next.geoId as string | number, next)
      return acc
    }, new Map<string | number, Item>())
  }, [legalEntitiesGeoprocessingGroupedByStreet?.items])

  const bounds = useMemo(() => {
    const items = streets
    if (!items || !isEnabled) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const multiPolygon = item.boundary as GeoJsonMultiPolygon
      const point = item.center as GeoJsonPoint
      const lineString = item.path as GeoJsonLineString
      const collection = item.collection as GeoJsonMultiLineString | any

      if (multiPolygon && multiPolygon.type === 'MultiPolygon') {
        for (const boundary of multiPolygon.coordinates) {
          const coordinates = boundary[0]
          for (const [longitude, latitude] of coordinates) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }

      if (lineString && lineString.type === 'LineString') {
        const coordinates = lineString.coordinates
        for (const [longitude, latitude] of coordinates) {
          if (south > latitude) {
            south = latitude
          }
          if (north < latitude) {
            north = latitude
          }
          if (west > longitude) {
            west = longitude
          }
          if (east < longitude) {
            east = longitude
          }
        }
      }

      if (collection && collection.type === 'MultiLineString') {
        for (const lineString of collection.coordinates) {
          for (const [longitude, latitude] of lineString) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }
    return {
      south: south - 0.0001,
      west: west - 0.0001,
      north: north + 0.0001,
      east: east + 0.0001,
    }
  }, [streets, isEnabled])

  const googleBounds = useMemo(() => {
    if (!google || !bounds) {
      return null
    }
    const southWest = new google.maps.LatLng(bounds.south, bounds.west)
    const northEast = new google.maps.LatLng(bounds.north, bounds.east)
    return new google.maps.LatLngBounds(southWest, northEast)
  }, [google, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  useEffect(() => {
    if (
      !isEnabled ||
      !google ||
      !streets ||
      !legalEntitiesGeoprocessingGroupedByStreetMap ||
      !isFinite(maxValue)
    ) {
      return
    }
    setItems(
      streets.map(street => {
        const legalEntity = legalEntitiesGeoprocessingGroupedByStreetMap.get(street.id as string)
        const countValue = legalEntity?.count ?? 0
        const highlightValue = isHighlightMode ? ((legalEntity?.highlight || 0) as number) : null
        const percent =
          highlightValue !== null && countValue !== 0
            ? highlightValue / Number(countValue)
            : countValue

        const percentColor =
          highlightValue !== null
            ? percent
            : maxValue && maxValue !== 0
            ? Number(countValue) / maxValue
            : 0

        const color = contrastGradientColor(percentColor)

        //Porcentagens de valor mínimo e máximo para legenda de destaques
        const maxLegendValue =
          maxValue && isHighlightMode ? `${formatAsPercentage(maxValue)}%` : maxValue

        const minLegendValue =
          minValue && isHighlightMode ? `${formatAsPercentage(minValue)}%` : minValue

        return {
          id: street.id,
          key: street.wayId,
          name: street.name,
          multiPolygon: street.boundary,
          path: street.path,
          center: null,
          color: color.hex('rgb'),
          percentColor,
          borderColor: color.darken(0.2).hex('rgb'),
          count: countValue,
          highlight: highlightValue,
          levelKey: 'streets',
          collection: street.collection,
          legendValues: [
            {
              maxValue: maxLegendValue,
              minValue: minLegendValue,
            },
          ],
        }
      }) as unknown as typeof items,
    )
  }, [
    legalEntitiesGeoprocessingGroupedByStreetMap,
    isHighlightMode,
    streets,
    google,
    maxValue,
    minValue,
    isEnabled,
  ])

  const overItems = useMemo(() => {
    if (!legalEntitiesGeoprocessingByStreet.items || !google || !bounds) {
      return null
    }

    const itemsByStreet = legalEntitiesGeoprocessingByStreet.items.map(item => {
      const centerGeoJson = item.pointOnStreet as {
        type: 'Point'
        coordinates: number[]
      } // | null
      return {
        type: 'Feature' as 'Feature',
        properties: {
          id: item.legalEntityId,
          name: `${item.tradeName || item.corporateName} (${item.legalEntityCnpj})`,
          key: item.legalEntityId,
          multiPolygon: null,
          path: null,
          count: 1,
          highlight: item.highlight,
          levelKey: childrenGeoLevel,
        },
        geometry: centerGeoJson,
      }
    })

    const index = new Supercluster({
      radius: 40,
      maxZoom: 17,
    })

    const uniqueItemsByStreet = itemsByStreet.filter(
      (obj, index, self) => index === self.findIndex(t => t.properties.id === obj.properties.id),
    )

    index.load(uniqueItemsByStreet)

    const clusters = index.getClusters([bounds.west, bounds.south, bounds.east, bounds.north], 16)

    const maxValue = Math.max(
      ...clusters.map(cluster => (cluster.properties.cluster ? cluster.properties.point_count : 1)),
    )

    const inner = clusters.map(cluster => {
      if (cluster.properties.cluster) {
        const innerItems = index
          .getLeaves(cluster.properties.cluster_id, 30000)
          .map(({ properties }) => properties)

        const maxValueAttendance = Math.max(
          ...innerItems?.map((item, index, data) => Number(item?.highlight) / Number(data.length)),
        )

        return maxValueAttendance
      }
    })

    const maxValueAttendanceIndexCluster = Math.max(...inner.filter(Number.isFinite))
    const minValue = Math.min(
      ...clusters.map(cluster => (cluster.properties.cluster ? cluster.properties.point_count : 1)),
    )

    return clusters.map(cluster => {
      if (cluster.properties.cluster) {
        const innerItems = index
          .getLeaves(cluster.properties.cluster_id, 30000)
          .map(({ properties }) => properties)

        const count = innerItems ? innerItems.length : 1
        const highlight = innerItems.filter(item => item.highlight).length

        const percent = isHighlightMode ? highlight / count : count / maxValue

        const percentAttendanceIndex =
          highlight && maxValueAttendanceIndexCluster
            ? highlight / count / maxValueAttendanceIndexCluster
            : 0

        const percentColor =
          definedOption?.type === 'legalEntityAttendanceIndex' ? percentAttendanceIndex : percent

        const color = contrastGradientColor(percentColor)

        // const percentColor = isHighlightMode ? highlight / count : count / maxValue
        // const color = contrastGradientColor(isHighlightMode ? highlight / count : count / maxValue)

        return {
          id: cluster.properties.cluster_id,
          key: cluster.properties.cluster_id,
          name: isHighlightMode
            ? `${formatAsBrNumber(highlight)} de ${formatAsBrNumber(
                cluster.properties.point_count,
              )} empresas`
            : `${formatAsBrNumber(cluster.properties.point_count)} empresas`,
          count,

          highlight: isHighlightMode ? highlight : null,
          cluster: new google.maps.LatLng(
            cluster.geometry.coordinates[1],
            cluster.geometry.coordinates[0],
          ),

          clusterItems: innerItems,
          levelKey: childrenGeoLevel as GeoChildrenLegalEntitiesPossibilities,
          center: null,
          multiPolygon: null,
          path: null,
          color: color.hex('rgb'),
          percentColor,
          borderColor: color.darken(0.2).hex('rgb'),
          legendValues: isHighlightMode ? [{}] : [{ maxValue: maxValue, minValue: minValue }],
        }
      }

      const percentColor = isHighlightMode
        ? cluster.properties.highlight
        : cluster.properties.highlight / 1
        ? 0
        : 1 / maxValue

      const color = contrastGradientColor(
        isHighlightMode
          ? cluster.properties.highlight
            ? cluster.properties.highlight / 1
            : 0
          : 1 / maxValue,
      )
      return {
        id: cluster.properties.id,
        key: cluster.properties.id,
        name: cluster.properties.name,
        count: 1,
        highlight: isHighlightMode ? (cluster.properties.highlight ? 1 : 0) : null,
        cluster: new google.maps.LatLng(
          cluster.geometry.coordinates[1],
          cluster.geometry.coordinates[0],
        ),
        clusterItems: [cluster.properties],
        levelKey: childrenGeoLevel as GeoChildrenLegalEntitiesPossibilities,
        center: null,
        multiPolygon: null,
        path: null,
        color: color.hex('rgb'),
        percentColor,
        borderColor: color.darken(0.2).hex('rgb'),
        legendValues: [{ maxValue: maxValue, minValue: minValue }],
      }
    }) as GeoItem[]
  }, [
    legalEntitiesGeoprocessingByStreet.items,
    isHighlightMode,
    streets,
    google,
    bounds?.east,
    bounds?.west,
    bounds?.north,
    bounds?.south,
  ])

  return {
    items: isEnabled ? [...(overItems || []), ...(items || [])] : null,
    bounds: googleBounds,
    isLoading:
      streetsResult.isLoading ||
      legalEntitiesGeoprocessingByStreet.isLoading ||
      legalEntitiesGeoprocessingGroupedByStreet.isLoading,
    error:
      streetsResult.error ||
      legalEntitiesGeoprocessingByStreet.error ||
      legalEntitiesGeoprocessingGroupedByStreet.error,
    queries: isEnabled
      ? [
          streetsResult.query,
          legalEntitiesGeoprocessingGroupedByStreet.query,
          legalEntitiesGeoprocessingGroupedByStreet.query,
        ]
      : [],
  }
}
